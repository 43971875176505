import { Link } from "react-router-dom";
import styles from "../../Navigation.module.css";
const StaffNav = () => {
  return (
    <>
      <Link to={"/history/staff"} className={styles.navLink}>
        Borrowing History
      </Link>
      <Link to={"/usermanagement"} className={styles.navLink}>
        User Management
      </Link>
      <Link to={"/feedback"} className={styles.navLink}>
        Feedback
      </Link>
    </>
  );
};

export default StaffNav;
