import { useEffect, useState } from "react";
import axios from "axios";
import IAsset from "@/models/asset/Asset";
import { useSearchParams } from "react-router-dom";
import { useAssetBorrowingStore } from "@/store/asset_borrowing/assetBorrowingStore";
import { useAssetBorrowingNext } from "../AssetBorrowing";
import AssetSelect from "../../components/asset/AssetSelect";
import { useUserStore } from "@/store/user/userStore";
import Search from "@/components/search/Search";

const ChooseAssetItems = () => {
  const assetBorrowingStore = useAssetBorrowingStore();
  const { setAssetBorrowingNext } = useAssetBorrowingNext();
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [lastPage, setLastPage] = useState(1);
  const userStore = useUserStore();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentQuery, setCurrentQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const selectedAssets = assetBorrowingStore.data.assets;
      if (selectedAssets !== undefined && selectedAssets.length > 0) {
        setAssetBorrowingNext(true);
      } else {
        setAssetBorrowingNext(false);
      }
  }, [assetBorrowingStore.data.assets]);


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    let ignore = false;

    const fetchData = async (p: number) => {
      setLoading(true);
      try {
        const res = await axios.post(
          import.meta.env.VITE_API_URL + "asset-borrowing/borrowable-assets",
          {
            data: {
              page: p,
              rooms: [assetBorrowingStore.data.room],
              email: userStore?.data?.Email,
              is_paginate: true,
              search: searchQuery,
            },
          },
          { signal } // Pass the signal to axios
        );

        if (ignore) return;

        const data = res.data.data;
        setLastPage(data.last_page ?? 1);
        setAssets(data.data);
        setCurrentPage(p); 
        setCurrentQuery(searchQuery)
      } catch (error) {
        if (!signal.aborted) {
          console.error(error);
          setAssets([]);
        }
      } finally {
        if (!ignore) {
          setLoading(false); 
        }
      }
    };

    const page = getPage(); 

    if (assetBorrowingStore.data.room !== -1 && !ignore) {
      if (assets.length <= 1 || page !== currentPage || currentQuery != searchQuery) {
        fetchData(page);
      }
    }

    return () => {
      ignore = true;
      controller.abort(); 
    };
  }, [assetBorrowingStore, searchParams, searchQuery]);

  const handleSelect = (asset: IAsset) => {
    const selected = assetBorrowingStore.data.assets
    const isSelected = selected.some((a) => a.id === asset.id);
    const newSelection = isSelected
      ? selected.filter((a) => a.id !== asset.id)
      : [...selected, asset];
    assetBorrowingStore.update("assets", newSelection);
  };

  const getPage = () => {
    const page = searchParams.get("page");
    return page ? +page : 1;
  };

  const changePage = (page: number) => {
    if (page < 1 || page > lastPage) return;
    setSearchParams({ page: page + "" });
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  return (
    <div className={`w-full`}>
      {!loading && (<Search
        containerClassName="py-4"
        onSearch={handleSearch}
        placeHolder="Search Asset Name"
        // value={searchQuery}
      />)}
      <AssetSelect assets={assets} loading={loading} handleSelect={handleSelect} currentPage={getPage()} lastPage={lastPage} changePage={changePage} title="Asset"/>
    </div>
  );
};

export default ChooseAssetItems;
