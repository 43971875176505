import React from 'react';

interface LoadingIndicatorProps {
  loading: boolean;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ loading }) => {
  return loading ? (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-[1002]">
      <div className=" p-5 rounded">
        <div className="w-12 h-12 border-4 border-t-4 border-muted-foreground border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    </div>
  ) : null;
};

export default LoadingIndicator;
