"use client";

import React, { useState } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogFooter,
} from "@/components/ui/dialog/Dialog"; 
import { Button } from "@/components/ui/button/Button"; 
import { Option } from "@/components/multi-select-filter/MultiSelectFilter"; 
import { IShift } from "@/models/shift/Shift"; 
import DateSelect from "@/pages/main/borrowing/components/date/DateSelect";

type Props = {
  shiftData: IShift[];
  loading: boolean;
  selectedShifts: Option[] | Option | null;
  onChangeShifts: (options: Option[] | Option | null) => void;
  date: Date | undefined;
  onSelectDate: (date: Date | undefined) => void;
  title: string;
  FilterComponent: React.ReactElement;
  onSave: () => void;
};

const DateSelectDialog: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false); 

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="w-full">Update Date and Shift</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{props.title}</DialogTitle>
        </DialogHeader>
        
        <DateSelect {...props} /> 

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Close</Button>
          </DialogClose>
          <Button
            variant="default"
            onClick={() => {
              props.onSave();
              setOpen(false);
            }}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DateSelectDialog;
