import { cn } from "@/lib/utils";
import { TableBody, TableCell, TableRow } from "../table/Table";
import { Skeleton } from "./Skeleton";

type Props = {
  rows: number;
  columns: number;
  skeletonClassName?: string;
};

const SkeletonTable = (props: Props) => {
  return (
    <TableBody>
      {[...Array(props.rows).keys()].map((i) => (
        <TableRow key={i + "r"}>
          {[...Array(props.columns).keys()].map((j) => (
            <TableCell key={j + "c"}>
              <Skeleton
                className={cn(
                  "w-full rounded-md bg-slate-200 h-12",
                  props.skeletonClassName
                )}
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default SkeletonTable;
