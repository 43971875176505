import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import AppRoute from './route'
import AppProvider from './provider'
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

// const msalConfig = {
//   auth: {
//     clientId: import.meta.env.VITE_APP_MICROSOFT_CLIENT_ID,
//     authority: import.meta.env.VITE_APP_MICROSOFT_TENANT_ID,
//     redirectUri: import.meta.env.VITE_APP_REDIRECT_URI,
//     navigateToLoginRequestUrl: false, 
//   },
//   cache: {
//     cacheLocation: import.meta.env.VITE_APP_CACHE_LOCATION || 'sessionStorage', 
//     storeAuthStateInCookie: import.meta.env.VITE_APP_STORE_AUTH_STATE_IN_COOKIE === 'true', 
//   },
// };

const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_PUBLIC_CLIENT_ID || "",
    authority: import.meta.env.VITE_PUBLIC_TENANT_ID || "",
    redirectUri: import.meta.env.VITE_PUBLIC_REDIRECT_URI || "",
    postLogoutRedirectUri:
      import.meta.env.VITE_PUBLIC_POST_LOGOUT_REDIRECT_URI || "",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: import.meta.env.VITE_PUBLIC_CACHE_LOCATION || "",
    storeAuthStateInCookie:
      import.meta.env.VITE_PUBLIC_STORE_AUTH_STATE_IN_COOKIE === "true",
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.createRoot(document.getElementById('root')!).render(
  
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
    <AppProvider>
      <AppRoute />
    </AppProvider>
    </MsalProvider>
  </React.StrictMode>,
)
