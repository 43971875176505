import { useEffect, useState } from "react";
import axios from "axios";

const useMessierId = () => {
  const [messierId, setMessierId] = useState<string | null>(null);
  const [status, setStatus] = useState<string>("");

  const fetchData = async (ignore: boolean) => {
    setStatus("loading");
    try {
      const res = await axios.get(`${import.meta.env.VITE_API_URL}auth/messierId`, {
        withCredentials: true 
      });
      if (ignore) {
        return;
      }

      if (res.data.data && res.data.data.messier_id) {
        setStatus("success");
        setMessierId(res.data.data.messier_id);
      } else {
        setStatus("error");
      }
    } catch (error) {
      setStatus("error");
    }
  };

  useEffect(() => {
    let ignore = false;
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return { messierId, status };
};

export default useMessierId;
