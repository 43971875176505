import { useState, useEffect } from "react";
import { IRoom } from "@/models/room/Room";
import axios from "axios";
import LabSelect from "../../components/laboratory/LabSelect";
import RoomSelect from "../../components/rooms/RoomSelect";
import { IRoomType } from "@/models/room/RoomType";
import { useAssetBorrowingStore } from "@/store/asset_borrowing/assetBorrowingStore";
import { useAssetBorrowingNext } from "../AssetBorrowing";
import { useUserStore } from "@/store/user/userStore";

const ChooseAssetRooms = () => {
  const [rooms, setRooms] = useState<IRoom[]>([]);
  const [roomTypes, setRoomTypes] = useState<IRoomType[]>([])
  const [loading, setLoading] = useState(false);
  const store = useAssetBorrowingStore();
  const userStore = useUserStore();
  const { setAssetBorrowingNext } = useAssetBorrowingNext();
  const [selectedType, setSelectedType] = useState<number>(-1)

  useEffect(() => {
    const selectedRoom = store.data.room;
    if (selectedRoom !== undefined && selectedRoom > 0) {
      setAssetBorrowingNext(true);
    } else {
      setAssetBorrowingNext(false);
    }
  }, [store.data.room]);

  const onSelect = (room: IRoom) => {
    store.update("room", room.id);
    store.update("room_type", room.type?.id)
    store.update("room_code", room.room_code);
  };

  const onSelectType = (id: number) => {
    store.update("room_type", id);
    setSelectedType(id);
  }

  useEffect(() => {
    let ignore : boolean = false;
    if (store.data.location < 0) return;
    const fetchData = async () => {
      try {
        const res = await axios.get(
          import.meta.env.VITE_API_URL + "room-type/asset-borrowing",
          {
            params: {
              room_location_id: store.data.location,
              email: userStore.data?.Email
            },
            withCredentials: true,
          }
        );
        if (ignore === true) return;
        setRoomTypes(res.data.data);
        setSelectedType(res.data.data[0].id);
      } catch (error) {
        setRoomTypes([]);
      }
    };
    fetchData();
    return () => {
        ignore = true;
    }
  }, [store.data.location]);


  useEffect(() => {
    if (selectedType < 0) return;
    if(store.data.room_type){
      let ignore = false;

      const fetchData = async (
        ignore: boolean
      ) => {
        setLoading(true)
        try {
          const res = await axios.get(
            import.meta.env.VITE_API_URL + "room/asset-borrowing",
            {
              params: {
                room_location_id: store.data.location,
                room_type_id: selectedType,
                search: undefined,
                email: userStore.data?.Email
              },
              withCredentials: true,
            }
          );
          if (ignore === true) return;
          setRooms(res.data.data);
        } catch (error) {
          console.log(error);
        }
        setLoading(false)
      };

      fetchData(ignore);
      return () => {
        ignore = true;
      };
    }
  }, [store.data.location, selectedType]);

  return (
    <RoomSelect onSubmit={() => {}} data={rooms} loading={loading} onSelect={onSelect} selected={store.data.room} onSoftwareChange={() => {}}>
      <LabSelect        
        data={roomTypes}
        selected={selectedType}
        onSelect={onSelectType}
      />
    </RoomSelect>
  );
};

export default ChooseAssetRooms;
