import React, { useState } from 'react';
import styles from "./MultiSelectFilter.module.css";
import { FaCircleCheck, FaCirclePlus } from 'react-icons/fa6';

export interface Option {
  id: string;
  name: string;
}

interface Props<T extends Option> {
  options: Option[];
  selected: Option | null;
  handleSelected: (selectedOption: T | null) => void;
}

const SingleSelectFilter = ({ options, handleSelected, selected }: Props<Option>) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleOption = (option: Option) => {
    if (selected && selected.id === option.id) {
      handleSelected(null);
    } else {
      handleSelected(option);
    }
  };

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex flex-col min-w-[30rem] w-fit">
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Search by the name of the item"
        className="w-full px-4 py-2 mb-4 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <ul className={styles.container}>
        {filteredOptions.map((option) => (
          <li key={option.id} className={styles.item} onClick={() => handleOption(option)}>
            <span className='text-sm flex-1 pe-1 text-muted-foreground'>{option.name}</span>
            {selected && selected.id === option.id ? (
              <FaCircleCheck className='text-green-600 hover:text-green-500 transition-colors ease-linear'/>
            ) : (
              <FaCirclePlus size={"1rem"} className='text-blue-600 hover:text-blue-500 transition-colors ease-linear'/>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SingleSelectFilter;
