import { ReactNode, useEffect } from "react";
import { useUserStore } from "@/store/user/userStore";
import { useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "@/components/ui/toast/Toaster";

type Props = {
  children: ReactNode;
};

const GuestLayout = (props: Props) => {
  const userStore = useUserStore();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname.includes("/login")) {
      const checkUser = async () => {
        const auth = await userStore.getData();
        if (auth) {
          navigate("/");
        }
      };
      checkUser();
    }
  }, [location.pathname]);

  return <div className="flex-1 min-h-screen">{props.children} <Toaster /></div>;
};

export default GuestLayout;
