import styles from "./FeedbackForm.module.css";
import { FaRegSmile } from "react-icons/fa";
import { FaRegSadCry } from "react-icons/fa";
import { FaRegLaughBeam } from "react-icons/fa";
import { ImConfused } from "react-icons/im";
import { BsEmojiExpressionless } from "react-icons/bs";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button/Button";
import { motion } from "framer-motion";
import axios from "axios";
import { Feedback } from "@/models/feedback/Feedback";
import Reaction from "./reaction/Reaction";
import Type from "./type/Type";
import { useUserStore } from "@/store/user/userStore";
import Toast from "@/components/toast/Toast";
import LoadingIndicator from "@/components/ui/loading/LoadingIndicator";

const FeedbackForm = () => {
  const [selectedIcon, setSelectedIcon] = useState(-1);
  const [selectedType, setSelectedType] = useState(-1);
  const [newFeedback, setNewFeedback] = useState<Partial<Feedback>>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sucess, setSucess] = useState("");

  const userStore = useUserStore();

  useEffect(() => {
    if (error !== "") {
      const timer = setTimeout(() => {
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  // console.log(userStore.data);

  const handleSelectedIcon = (param: number) => {
    setSelectedIcon(param);
    setNewFeedback({
      ...newFeedback,
      satisfaction: param,
    });
  };

  const handleSelectedType = (id: number, name: string) => {
    setSelectedType(id);
    setNewFeedback({
      ...newFeedback,
      feedback_category: {
        id: id,
        name: name,
      },
    });
  };

  const validateAndTransformFeedback = (partialFeedback: Partial<Feedback>): Feedback | null => {
    console.log(partialFeedback.message);
    if (partialFeedback.message !== undefined && partialFeedback.feedback_category !== undefined && partialFeedback.satisfaction !== undefined) {
      return partialFeedback as Feedback;
    }
    setError("All fields must be filled");
    return null; // or throw an error, or handle invalid state
  };

  const handleSubmit = async () => {
    //validate
    setLoading(true);
    const transformed = validateAndTransformFeedback(newFeedback);
    if (!transformed) {
      setLoading(false);
      console.error("Invalid feedback data:", newFeedback);
      return;
    }

    if (!userStore.data) {
      setError("User is not logged in");
      return;
    }

    const reporter_name = userStore.data.UserName;
    const reporter_email = userStore.data.Email;
    const binusian_id = userStore.data.BinusianId;

    const { message, satisfaction, feedback_category } = transformed;

    // Ensuring feedbackCategoryId is safely accessed
    const feedbackCategoryId = feedback_category ? feedback_category.id : undefined;

    try {
      const res = await axios.post(`${import.meta.env.VITE_API_URL}feedback/submit-feedback`, {
        reporter_name: reporter_name,
        message: message,
        reporter_email: reporter_email,
        binusian_id: binusian_id,
        category_id: feedbackCategoryId,
        satisfaction: satisfaction,
      }, {withCredentials: true});
      console.log(res);

      const data: {
        succesful: any[];
        failed: any[];
      } = res.data.data;
      const { failed } = data;
      if (failed && failed.length > 0) {
        return {
          status: false,
          failed: failed,
        };
      } else {
        console.log("Sucess");
        setSucess("Sucessfully added new feedback");

        return {
          status: true,
          failed: null,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        status: false,
        failed: null,
      };
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-1 justify-center px-2 py-4 md:px-5 w-full h-full ">
      {error && <Toast message={error} type="error" />}
      {sucess && <Toast message={sucess} type="confirmation" />}
      <LoadingIndicator loading={loading} />
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }} className="flex bg-secondary rounded-lg flex-col p-4 flex-1 justify-center  md:px-5 h-full items-center ">
        <form className="w-full rounded-lg flex flex-col xl:w-2/3 border-pink-100 bg-background p-8 xl:p-16">
          <div className=" mb-4 flex items-center justify-center  pb-5 flex-col gap-3 border-b-2 ">
            <h3 className="text-3xl text-center text-primary xl:text-5xl">Submit Your Feedback</h3>
            <h4 className="text-md text-center xl:text-xl">We highly appreciate your feedback to imporove our website</h4>
          </div>
          <div className="flex items-start justify-center  flex-col gap-3 rounded-md pb-3 mb-3 xl:text-lg">
            <p className="font-bold text-md">How's your experience with our website?</p>
            <div className="flex gap-5 ">
              <Reaction Icon={FaRegSadCry} color={"#0A2342"} onclick={() => handleSelectedIcon(1)} id={1} selected={selectedIcon} />
              <Reaction Icon={ImConfused} color={"#6D9BC3"} onclick={() => handleSelectedIcon(2)} id={2} selected={selectedIcon} />
              <Reaction Icon={BsEmojiExpressionless} color={"#E2725B"} onclick={() => handleSelectedIcon(3)} id={3} selected={selectedIcon} />
              <Reaction Icon={FaRegSmile} color={"#228B22"} onclick={() => handleSelectedIcon(4)} id={4} selected={selectedIcon} />
              <Reaction Icon={FaRegLaughBeam} color={"#FFCC00"} onclick={() => handleSelectedIcon(5)} id={5} selected={selectedIcon} />
            </div>
          </div>
          <div className="flex items-start justify-center  flex-col gap-3 rounded-md  pb-3 mb-3 xl:text-lg">
            <p className="font-bold text-md">Please select your feedback category</p>
            <div className="flex gap-3 text-md">
              <Type text="Problem" id={1} selected={selectedType} onclick={() => handleSelectedType(1, "Problem")} color="#FF0000" />
              <Type text="Suggestion" id={2} selected={selectedType} onclick={() => handleSelectedType(2, "Suggestion")} color="#0000FF" />
              <Type text="Compliment" id={3} selected={selectedType} onclick={() => handleSelectedType(3, "Compliment")} color="#228B22" />
            </div>
          </div>
          <div className="flex items-start justify-center flex-col gap-3 rounded-md pb-3 mb-3 xl:text-lg">
            <p className="font-bold text-md">Please leave your feedback below</p>
            <textarea required className={`${styles.textArea} bg-background text-md`} onChange={(e) => setNewFeedback({ ...newFeedback, message: e.target.value })} />
          </div>
          <div className="flex items-center justify-center">
            <Button className="w-full bg-amber-600 transition-all ease-linear text-white hover:bg-amber-600/90 hover:scale-105 text-xl" type="button" onClick={handleSubmit} disabled={loading}>
              Submit
            </Button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default FeedbackForm;
