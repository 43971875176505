
import { Grid } from "../grid/Grid";
import { Skeleton } from "./Skeleton";
import { cn } from "@/lib/utils";

type Props = {
  count: number;
  skeltonClassName?: string;
};

const SkeletonGrid = (props: Props) => {
  return (
    <Grid header="Loading Data">
      {[...Array(props.count).keys()].map((a) => (
        <Skeleton
          key={a}
          className={cn(
            "w-full rounded-md bg-slate-200 h-24",
            props.skeltonClassName
          )}
        />
      ))}
    </Grid>
  );
};

export default SkeletonGrid;
