import styles from "./AssetBorrowing.module.css";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button/Button";
import { motion } from "framer-motion";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { useAssetBorrowingStore } from "@/store/asset_borrowing/assetBorrowingStore";
interface BorrowingPath {
  title: string;
  url: string;
}

const paths: Array<BorrowingPath> = [
  { title: "Campus", url: "/borrowing/asset/campus" }, // 0
  { title: "Room", url: "/borrowing/asset/choose-room" }, // 1
  { title: "Asset", url: "/borrowing/asset/choose-asset" }, // 2
  { title: "Date", url: "/borrowing/asset/choose-date" }, // 3
  { title: "Data", url: "/borrowing/asset/borrower-data" }, // 4
  { title: "Submit", url: "/borrowing/asset/submit" }, // 5
];

type ContextType = {
  setAssetBorrowingNext: React.Dispatch<React.SetStateAction<boolean>>;
};

const pageVariants = {
  initial: { opacity: 0, x: 0, y: 20 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: 20 },
};

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: 0.5,
};

const AssetBorrowing = () => {
  const [step, setStep] = useState(0);
  const [assetBorrowingNext, setAssetBorrowingNext] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const store = useAssetBorrowingStore();

  const calculateProgressBarWidth = (step: number) => {
    const percent = (step / (paths.length - 1)) * 100;
    return { width: percent + "%" };
  };

  const prevStep = () => {
    if (step <= 0) return;
    navigate(paths[step - 1].url);
  };
  const nextStep = () => {
    const newStep = step + 1;
    if (newStep >= paths.length) return;
    setAssetBorrowingNext(false);
    localStorage.setItem(
      "asset_borrowing",
      JSON.stringify({ ...store.data, current_page: newStep + 1 })
    );
    store.update("current_page", newStep + 1);
    navigate(paths[newStep].url);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const idx = paths.findIndex((v) => {
      return v.url === location.pathname;
    });
    setStep(idx);
  }, [location.pathname]);

  useEffect(() => {
    const local = localStorage.getItem("asset_borrowing");
    if (local) {
      const data = JSON.parse(local);
      store.setBorrowing(data);
    }
  }, []);

  return (
    <div className="flex flex-1 justify-center px-2 py-4 md:px-5 w-full h-full">
      <div className="w-full bg-secondary rounded-lg flex flex-col p-4">
        <div className={`${styles.progressCon}`}>
          <div className={`${styles.progress}`}>
            <div
              className={` ${styles.bar}`}
              style={calculateProgressBarWidth(step)}
            />
            {paths.map((bp, i) => (
              <div className={`${styles.progressStepCon}`} key={i}>
                <Link
                  to={bp.url}
                  className={`${styles.progressStep} ${
                    step >= i
                      ? "bg-blue-500 text-white after:text-blue-500"
                      : "bg-secondary text-blue-500 after:text-black pointer-events-none"
                  }`}
                >
                  {step > i ? <span>&#10003;</span> : `${i + 1}`}
                </Link>
              </div>
            ))}
          </div>
        </div>
        <motion.div
          key={location.pathname}
          initial="initial"
          animate="enter"
          exit={"exit"}
          className={styles.content}
          variants={pageVariants}
          transition={pageTransition}
        >
          <Outlet context={{ setAssetBorrowingNext } satisfies ContextType} />
        </motion.div>
        <div className={"flex justify-end gap-4 py-4 relative h-12"}>
          <Button
            type="button"
            onClick={prevStep}
            variant={"gray"}
            disabled={step === 0}
            className="absolute left-0 w-12"
          >
            <FaArrowLeft/>
          </Button>
          {step + 1 < paths.length && (
            <Button
              type="button"
              onClick={nextStep}
              variant={"blue"}
              disabled={assetBorrowingNext === false}
              className="absolute right-0 w-12"
            >
              <FaArrowRight/>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssetBorrowing;

export const useAssetBorrowingNext = () => {
  return useOutletContext<ContextType>();
};
