import { IUser } from "@/models/user/User";
import axios from "axios";
import { create } from "zustand";

interface UserState {
    data: IUser | undefined,
    getData: () => Promise<boolean>,
    logout: () => Promise<boolean>
}

export const useUserStore = create<UserState>((set) => ({
    data: undefined,
    getData: async () => {
        try {
            const res = await axios.get(import.meta.env.VITE_API_URL + "auth/data", {
                withCredentials: true,
            });
            set((state) => ({
                ...state,
                data: res.data.data,
            }));
            return true;
        } catch (error) {
            set((state) => ({
                ...state,
                data: undefined,
            }));
            return false;
        }
    },
    logout: async () => {
        try {
            await axios.post(import.meta.env.VITE_API_URL + "auth/logout", {}, {
                withCredentials: true,
            });

            localStorage.removeItem('asset_borrowing');
            localStorage.removeItem('room_borrowing');            
            set((state) => ({
                ...state,
                data: undefined,
            }));
            return true
        } catch (error) {
            return false;
        }
    }
}))