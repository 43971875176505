import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog/Dialog";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectGroup,
  SelectValue,
} from "@/components/ui/select/Select";
import { useState, SyntheticEvent, useEffect } from "react";
import { AssetTransactions } from "../transactions/AssetTransactions";
import DateSelectDialog from "./DateSelectDialog";
import axios from "axios";
import { IShift } from "@/models/shift/Shift";
import SingleSelectFilter from "@/components/multi-select-filter/SingleSelectFilter";
import { convertToOptions } from "@/pages/main/borrowing/components/date/DateSelect";

type Props = {
  onFinalize: (status: string) => void;
  dialogTitle: string; 
  borrowingCurrent?: any;
  assetBorrowingCurrent?: any;
  check_in_date? : any;
  check_out_date? : any;
  check_in_shift? : any;
  check_out_shift? : any;
  room_id? : any;
  room_code? : any;
  room_type_id? : any;
  assetId? : any;
  onUpdate?: () => void;
};

export function FinalizeDialog({ onFinalize, dialogTitle, borrowingCurrent, assetBorrowingCurrent, check_in_date, check_in_shift, check_out_date, check_out_shift, room_id, room_code, room_type_id, assetId, onUpdate }: Props) {
  const [open, setOpen] = useState(false); 
  const [status, setStatus] = useState<string>("");

  const [pickUpDate, setPickUpDate] = useState<any>(check_in_date);
  const [returnDate, setReturnDate] = useState<any>(check_out_date);

  const [pickUpShifts, setPickUpShifts] = useState<IShift[]>([]);
  const [returnShifts, setReturnShifts] = useState<IShift[]>([]);
  const [selectedPickUpShift, setSelectedPickUpShift] = useState<any>(check_in_shift);
  const [selectedReturnShift, setSelectedReturnShift] = useState<any>(check_out_shift);
  const [loading, setLoading] = useState(false);
  const [rLoading, setRLoading] = useState(false);

  const handlePickUpShiftChange = (selected: any) => {
    if (selected) {
      setSelectedPickUpShift(selected);
    } else {
      setSelectedPickUpShift(null);
    }
  };

  const handlePickUpDate = (value: Date | undefined) => {
    if (value !== pickUpDate) {
      setPickUpDate(value)
      setSelectedPickUpShift(null);
    }
  };

  const handleReturnShiftChange = (selected: any) => {
    if (selected) {
      setSelectedReturnShift(selected);
    } else {
      setSelectedReturnShift(null);
    }
  };

  // Handle return date selection
  const handleReturnDate = (value: Date | undefined) => {
    if (value !== returnDate) {
      setReturnDate(value)
      setSelectedReturnShift(null);
    }
  };

  const formatDateString = (dateString: string) => {
    const date = new Date(dateString); 
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`; 
  };

  const handleSave = async (type: string, date : string, shift: string) => {
    // console.log(formatDateString(date))
    // console.log(date)
    // console.log({
    //   type,
    //   id: assetId,
    //   date: formatDateString(date),
    //   shift_id: shift,
    // })
    try {
      const response = await axios.patch(import.meta.env.VITE_API_URL+ 'asset-borrowing/staff/date-shift', {
        type,
        id: assetId,
        date: formatDateString(date),
        shift_id: shift,
      });

      console.log('Response:', response.data);
      setSelectedPickUpShift(null);
      setPickUpDate(null)
      onUpdate?.()
      setOpen(false)

    } catch (error) {
      console.error('Error updating date and shift:', error);
    }
    
  };

  useEffect(() => {
    if (!pickUpDate && !dialogTitle.includes("Check In")) return;

    const fetchData = async () => {
      setLoading(true);
      const res = await axios.get(`${import.meta.env.VITE_API_URL}shift/available`, {
        params: {
          room_id: room_id,
          room_code: room_code,
          room_type_id: room_type_id,
          date: pickUpDate,
        },
        withCredentials: true,
      });
      setPickUpShifts(res.data.data);
      setLoading(false);
    };
    fetchData();
  }, [pickUpDate]);

  useEffect(() => {
    if (returnDate && !dialogTitle.includes("Check Out")) return;

    const fetchData = async () => {
      setRLoading(true);
      const res = await axios.get(`${import.meta.env.VITE_API_URL}shift/available`, {
        params: {
          room_id: room_id,
          room_code: room_code,
          room_type_id: room_type_id,
          date: returnDate,
        },
        withCredentials: true,
      });
      console.log(res.data.data)
      setReturnShifts(res.data.data);
      setRLoading(false);
    };
    fetchData();
  }, [returnDate]);

  const handleFinalize = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (status) {
      onFinalize(status);
    }
  };

  const statusStyles = {
    Present: "text-green-600",
    Absent: "text-red-600",
    Cancel: "text-yellow-600",
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive">Finalize</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{dialogTitle}</DialogTitle> 
        </DialogHeader>
        {borrowingCurrent?.data && assetBorrowingCurrent?.data && (<AssetTransactions
              borrowingCurrent={borrowingCurrent}
              assetBorrowingCurrent={assetBorrowingCurrent}
          />)}


      {dialogTitle.includes("Check In") && (
        <DateSelectDialog
          onSave={() => handleSave("check-in", pickUpDate, selectedPickUpShift?.id.toString())}
          title="Select Check In Date"
          date={pickUpDate}
          loading={loading}
          shiftData={pickUpShifts}
          onChangeShifts={handlePickUpShiftChange}
          onSelectDate={handlePickUpDate}
          selectedShifts={selectedPickUpShift}
          FilterComponent={<SingleSelectFilter options={convertToOptions(pickUpShifts)}
          handleSelected={handlePickUpShiftChange}
          selected={selectedPickUpShift}/>}
        />
      )}

      {dialogTitle.includes("Check Out") && (
          <DateSelectDialog
          onSave={() => handleSave("check-out", returnDate, selectedReturnShift?.id.toString())}
            title="Select Check Out Date"
            date={returnDate}
            loading={rLoading}
            shiftData={returnShifts}
            onChangeShifts={handleReturnShiftChange}
            onSelectDate={handleReturnDate}
            selectedShifts={selectedReturnShift}
            FilterComponent={<SingleSelectFilter options={convertToOptions(returnShifts)}
            handleSelected={handleReturnShiftChange}
            selected={selectedReturnShift}/>}
          />
        )}

        <div className="text-lg font-semibold">
          Please select the final status for this borrowing:
        </div>
        <div className="mt-2">
          <Select onValueChange={setStatus} value={status}>
            <SelectTrigger>
              <SelectValue placeholder="Select status" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Status</SelectLabel>
                <SelectItem 
                  value="Present" 
                  className={statusStyles.Present}
                >
                  Present
                </SelectItem>
                <SelectItem 
                  value="Absent" 
                  className={statusStyles.Absent}
                >
                  Absent
                </SelectItem>
                <SelectItem 
                  value="Canceled" 
                  className={statusStyles.Cancel}
                >
                  Canceled
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <DialogFooter className="justify-end flex flex-row gap-4">
          <Button
            type="button"
            variant={"default"}
            onClick={handleFinalize}
            disabled={!status}
          >
            Finalize
          </Button>
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
      


    </Dialog>
  );
}
