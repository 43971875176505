import { Button } from "@/components/ui/button/Button";
import { Input } from "@/components/ui/input/Input";
import { Label } from "@/components/ui/label/Label";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { FaPlus, FaX } from "react-icons/fa6";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./BorrowerData.module.css";
import { Checkbox } from "@/components/ui/checkbox/Checkbox";
import { useRoomBorrowingStore } from "@/store/room_borrowing/roomBorrowingStore";
import axios from "axios";
import { cn } from "@/lib/utils";
import { useToast } from "@/lib/hooks/useToast";
import { useRoomBorrowingNext } from "../RoomBorrowing";
import { useUserStore } from "@/store/user/userStore";

type Team = {
  id: number;
  value: string;
};

const BorrowerData = () => {
  const store = useRoomBorrowingStore();
  const { toast } = useToast();
  const userStore = useUserStore();
  const [members, setMembers] = useState<Team[]>([]);
  const [loading, setLoading] = useState(false);
  const { setRoomBorrowingNext } = useRoomBorrowingNext();

  useEffect(() => {
    if (store?.data?.team?.length > 0) {
      const formattedMembers = store.data.team.map((teamMember, index) => ({
        id: index + 1, 
        value: teamMember.email 
      }));
      setMembers(formattedMembers);
    } else {
      setMembers([]);
    }
  }, [store?.data?.team]);

  const addMember = () => {
    const newId = members.length === 0 ? 1 : members[members.length - 1].id + 1;
    setMembers((prev) => [...prev, { id: newId, value: "" }]);
  };

  const removeMember = (id: number) => {
    setMembers((prev) => prev.filter((v) => v.id !== id));
  };

  const handleInputTeam = (id: number, e: ChangeEvent<HTMLInputElement>) => {
    const idx = members.findIndex((v) => v.id === id);
    const arr = members;
    arr[idx].value = e.target.value;
    setMembers([...arr]);
  };

  const fetchBinusianData = async () => {
    const credentials: string[] = [];
    for (let index = 0; index < members.length; index++) {
      const m = members[index];
      credentials.push(m.value);
    }

    try {
      const res = await axios.post(
        `${import.meta.env.VITE_API_URL}user/binusian-data`,
        {
          credential: credentials,
        }
      );
      const data: {
        successful: any[];
        failed: any[];
      } = res.data.data;

      const { failed, successful } = data;
      if (failed && failed.length > 0) {
        return {
          status: false,
          failed: failed,
        };
      } else {
        const member = successful.map((item : any) => ({
          binusian_id: item.BinusianID,
          email: item.email[0].Email
        }));
        store.data.team = member
        return {
          status: true,
          failed: null,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        status: false,
        failed: null,
      };
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setRoomBorrowingNext(false);
    if (store.data.phone.trim() === "" || store.data.purpose.trim() === "") {
      toast({
        title: "Form error.",
        description: "Please fill all required fields...",
      });

      return;
    } else if (isNaN(+store.data.phone)) {
      toast({
        title: "Form error.",
        description: "Please fill phone number correctly...",
      });

      return;
    }
    setLoading(true);
    const res = await fetchBinusianData();
    setLoading(false);
    if (res.status) {
      toast({
        title: "User data successfully saved.",
        description: "Please proceed to next step..",
      });
      setRoomBorrowingNext(true);
    } else {
      console.log(res.failed);
      const msg = res.failed
        ? res.failed[0].credential + " " + res.failed[0].reason
        : "Please fill the form with correct credentials..";
      toast({
        title: "Team member data is invalid.",
        description: msg,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.inputContainer}>
        <Label>Purpose</Label>
        <Input
          placeholder="Borrowing Purpose"
          name="purpose"
          id="purpose"
          value={store.data.purpose}
          onChange={(e) =>
            store.update("purpose", e.target.value)
          }
        />
      </div>
      <div className={styles.inputContainer}>
        <Label>Phone</Label>
        <Input
          placeholder="Phone Number"
          name="phone"
          id="phone"
          value={store.data.phone}
          onChange={(e) =>
            store.update("phone", e.target.value)
          }
        />
      </div>
      {store?.data?.room_type != 6 && (<div className={styles.inputContainer}>
        <Label>Do you need internet?</Label>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="internet"
            name="internet"
            checked={store.data.need_internet ?? false}
            onCheckedChange={(checked) =>
              store.update("need_internet", checked)
            }
          />
          <label
            htmlFor="internet"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Yes, I do.
          </label>
        </div>
      </div>)}
      {store?.data?.room_type == 6 && (<div className={styles.inputContainer}>
        <Label>Do you need to request material?</Label>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="chemicals"
            name="chemicals"
            checked={store.data.need_chemicals ?? false}
            onCheckedChange={(checked) =>
              store.update("need_chemicals", checked)
            }
          />

        <label
          htmlFor="chemicals"
          className="text-sm font-medium leading-none"
        >
          Yes, I do.
        </label>
        <span className=" text-sm font-medium cursor-pointer text-primary" onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSdlWQoTAF7Sn_wjr67zXpps72AwuZy2tDkk8I1HKPTZ_FsRDg/viewform", "_blank")}>(Please fill the form here)</span>
        </div>
      </div>)}
      <div className={cn(styles.inputContainer, "pb-6")}>
        <div className="flex w-full gap-4 py-2 items-end">
          <div className="w-full">
            <Label className="text-lg text-gray-600">Leader</Label>
            <Input
              disabled={true}
              value={`${userStore.data?.Email}`}
            />
          </div>
          <Button
            type="button"
            onClick={addMember}
            variant={"blue"}
            className="w-60 flex items-center gap-4"
            disabled={loading}
          >
            Add Member <FaPlus />
          </Button>
        </div>
        <div className="flex flex-col w-full gap-2 max-h-48 overflow-y-auto px-6 pb-2">
          <AnimatePresence>
            {members.map((m, idx) => (
              <motion.div
                key={idx}
                className="w-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  type: "just",
                  ease: "linear",
                  duration: 0.5,
                }}
              >
                <Label htmlFor={`team${m.id}`}>Member {idx + 1}</Label>
                <div className="flex w-full items-center space-x-2">
                  <Input
                    id={`team${m.id}`}
                    placeholder="Member Email / Binusian ID"
                    className="flex-1"
                    onChange={(e) => handleInputTeam(m.id, e)}
                    value={m.value}
                  />
                  <button className="w-auto" type="button">
                    <FaX
                      onClick={() => removeMember(m.id)}
                      className="text-red-600 hover:text-red-600/75 cursor-pointer transition-colors ease-linear duration-200"
                    />
                  </button>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
      <div className="pt-4">
        <Button type="submit" variant={"blue"} disabled={loading}>
          Save Data
        </Button>
      </div>
    </form>
  );
};

export default BorrowerData;
