import logo from "@assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button/Button";
import { useForm, SubmitHandler } from "react-hook-form";
import { Input } from "@/components/ui/input/Input";
import axios from "axios";
import { useUserStore } from "@/store/user/userStore";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import LoadingIndicator from "@/components/ui/loading/LoadingIndicator";
import { useToast } from "@/lib/hooks/useToast";

type Inputs = {
  username: string;
  password: string;
};

const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const userStore = useUserStore();
  const { instance, accounts, inProgress } = useMsal();
  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const getUserSalt = async (username: string) => {
    try {
      const res = await axios.post(
        import.meta.env.VITE_API_URL + "user/get-salt",
        { username: username }
      );
      return { error: false, data: res.data };
    } catch (error) {
      console.error("Failed to get salt", error);
      toast({
        title: "Failed to get salt",
        description: "There was an error fetching the user salt. Please check your internet connection and try again. If the problem persists, contact administrator.",
      });
      
      return { error: true, message: "Failed to get salt" };
    }
  };
  
  const messierLogin = async (username: string, password: string) => {
    setLoading(true);
    try {
      const res = await axios.post(
        import.meta.env.VITE_API_URL + "auth/loginMessier",
        { username: username, password: password },
        { withCredentials: true }
      );
  
      if (res.data.status) {
        const status = await userStore.getData();
        if (status) navigate("/");
      } else {
        toast({
          title: "Login Failed",
          description: "Invalid username or password."
        });
      }
    } catch (error) {
      console.error("Login failed", error);
      toast({
        title: "Error",
        description: "Login failed. Please check your credentials. If the problem persists, contact administrator."
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);
    try {
      const getSalt = await getUserSalt(data.username);
      if (getSalt.error) return;

      const salt = getSalt.data.data;
      const encPass = EncryptToBase64(String(salt) + data.username, data.password);
      await messierLogin(data.username, encPass + "");
    } catch (error) {
      console.error("Error during form submission", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMicrosoftLogin = async () => {
    setLoading(true);
    try {
      const loginRequest = {
        scopes: ["user.read"],
        prompt: "select_account",
      };
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.error("Error during Microsoft login redirect", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let ignore = false;

    const handleRedirect = async () => {
      if (inProgress === "none" && accounts.length > 0) {
        setLoading(true);
        try {
          const tokenResponse = await instance.acquireTokenSilent({
            account: accounts[0],
            scopes: ["user.read"],
          });

          const microsoftToken = tokenResponse.idToken;

          const response = await axios.post(
            import.meta.env.VITE_API_URL + "auth/loginMicrosoft",
            { microsoft_token: microsoftToken },
            { withCredentials: true }
          );

          if (response.data.status) {
            if (!ignore) {
              await userStore.getData();
              navigate("/");
            }
          }
        } catch (error) {
          console.error("Failed to acquire token silently", error);
          toast({
            title: "Failed to acquire token silently",
            description: "Login failed. Please check your credentials. If the problem persists, contact administrator."
          });
        } finally {
          if (!ignore) {
            setLoading(false);
          }
        }
      }
    };

    handleRedirect();

    return () => {
      ignore = true;
    };
  }, [instance, accounts, inProgress]);

  return (
    <div className="flex p-4 items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 via-blue-400 to-blue-200">
      <LoadingIndicator loading={loading} />
      <div className="bg-white/65 p-8 rounded-xl shadow-lg w-92 md:w-96">
        <div className="flex justify-center items-center">
          <h1 className="text-2xl font-bold text-blue-600">LabFacility</h1>
          <img src={logo} alt="Binus University" className="mx-auto my-4" />
        </div>

        <Button
            type="button"
            className="mb-5 w-full bg-blue-700 text-white font-medium gap-2 py-2 mt-2 rounded-lg flex items-center justify-center"
            onClick={handleMicrosoftLogin}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/2048px-Microsoft_logo.svg.png"
              alt="Microsoft logo"
              className="h-6 w-6 mr-2"
            />
            Login for borrowing
        </Button>

        <div className="h-[0.1rem] w-full bg-gray-600 mb-4"/>
        <form  method="POST" onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
          <div>
            <label htmlFor="username" className="block text-gray-700">
              Username
            </label>
            <Input
              type="text"
              id="username"
              placeholder="User Initial e.g: LCXXX or XX22-1"
              className="w-full px-3 py-2 border rounded-lg shadow-sm bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              {...register("username", { required: true })}
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-gray-700">
              Password
            </label>
            <Input
              type="password"
              id="password"
              placeholder="Enter your password"
              className="w-full px-3 py-2 border rounded-lg shadow-sm bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              {...register("password", { required: true })}
            />
          </div>
          <div
            className={`text-red-600 py-2 text-center ${
              errors.username || errors.password ? "opacity-100" : "opacity-0"
            } transition-all ease-in-out duration-200`}
          >
            Please fill all fields
          </div>
          <Button type="submit" className="w-full rounded-lg bg-secondary text-secondary-foreground">
            Login for staff
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
