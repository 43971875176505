import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button/Button";
import axios from "axios";
import useQueryParams from "@/lib/hooks/useQueryParams";
import { Feedback } from "@/models/feedback/Feedback";
import FeedbackTable from "./table/FeedbackTable";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select/Select";

const FeedbackFilterComponent = ({ onFilter }: { onFilter: (filters: any) => void }) => {
  const [category, setCategory] = useState("");
  const [satisfaction, setSatisfaction] = useState("");

  const handleFilter = () => {
    onFilter({ category, satisfaction });
  };

  const categories = ["Problem", "Suggestion", "Compliment"];
  const satisfactions = [1, 2, 3, 4, 5];

  return (
    <div className="flex items-center justify-between p-4">
      <div className="flex">
        <div className="flex flex-col gap-2 p-4">
          <Select value={category} onValueChange={(v) => setCategory(v)}>
            <SelectTrigger>
              <SelectValue placeholder="All Categories" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="All">All Categories</SelectItem>
              {categories.map((s, i) => (
                <SelectItem value={s} key={i} className="mb-1">
                  {s}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col gap-2 p-4">
          <Select value={satisfaction} onValueChange={(v) => setSatisfaction(v)}>
            <SelectTrigger>
              <SelectValue placeholder="All Satisfaction" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="All">All Satisfaction</SelectItem>
              {satisfactions.map((s, i) => (
                <SelectItem value={s.toString()} key={i} className="mb-1">
                  {s.toString()}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <Button className="bg-blue-200" variant="outline" size="sm" onClick={handleFilter}>
        Filter
      </Button>
    </div>
  );
};

const ViewAllFeedback: React.FC = () => {
  const [data, setData] = useState<Feedback[]>([]);
  const [loading, setLoading] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const { params, updateParams } = useQueryParams();

  const handleFilter = (filter: { category: number; satisfaction: number }) => {
    updateParams(filter);
  };

  const changePage = (value: number) => {
    if (value >= 1 && value <= lastPage) {
      updateParams({ page: value.toString() });
    }
  };

  useEffect(() => {
    let ignore = false;
    const page = params.get("page") || 1;
    const category = params.get("category") || undefined;
    const satisfaction = params.get("satisfaction") || undefined;

    console.log("category: ", category);
    console.log("satisfaction: ", satisfaction);

    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(import.meta.env.VITE_API_URL + "/feedback/paginate", {
          params: {
            satisfaction: satisfaction === "All" ? undefined : satisfaction,
            category: category === "All" ? undefined : category,
            page: page ?? 1,
          },
          withCredentials: true,
        });
        if (ignore) return;
        setData(res.data.data.feedbacks);
        setLastPage(res.data.data.last_page);
      } catch (error) {
        console.error(error);
      }
      // setData(getUserData())
      setLoading(false);
    };

    fetchData();
    return () => {
      ignore = true;
    };
  }, [params]);

  return (
    <div className="container mx-auto py-10 px-8">
      <h1 className="text-lg mb-4">View All Feedback</h1>
      <FeedbackFilterComponent onFilter={handleFilter} />
      <FeedbackTable data={data} loading={loading} onChangePage={changePage} current_page={+(params.get("page") ?? 1)} last_page={lastPage} />
    </div>
  );
};

export default ViewAllFeedback;
