import LoadingIndicator from "@/components/ui/loading/LoadingIndicator";
import { useUserStore } from "@/store/user/userStore";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

const ProtectedLayout = () => {
  const navigate = useNavigate();
  const userStore = useUserStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const success = await userStore.getData();
      setLoading(false); 

      if (!success) {
        navigate("/home"); 
      }
    };

    if (userStore.data === undefined) {
      fetchUserData();
    } else {
      setLoading(false); 
    }
  }, [userStore]); 
  if (loading) {
    return <LoadingIndicator loading={loading} />;
  }

  if (!userStore.data && !loading) {
    return <Navigate to="/home" replace />;
  }

  return <Outlet />;
};

export default ProtectedLayout;
