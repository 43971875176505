import { useEffect, useState } from "react";
import axios from "axios";
import FilterComponent, { Filter } from "./components/filter/FilterComponent";
import { IBorrowingHistory } from "@/models/borrowing/BorrowingHistory";
import BorrowingTable from "@/components/borrowing/table/BorrowingTable";
import AssetBorrowingTable from "@/components/borrowing/table/AsssetBorrowingTable";
import useSemesters from "@/lib/hooks/useSemesters";
import useQueryParams from "@/lib/hooks/useQueryParams";
import useRoomByPic from "@/lib/hooks/useRoomByPic";
import { Button } from "@/components/ui/button/Button";
import { useUserStore } from "@/store/user/userStore";
import { IAssetBorrowingHistory } from "@/models/borrowing/AssetBorrowingHistory";

const StaffHistory = () => {
  const userStore = useUserStore();
  const [data, setData] = useState<any[]>([]);
  const [data2, setData2] = useState<any[]>([]);

  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { semesters } = useSemesters();
  const { rooms } = useRoomByPic(userStore.data?.UserName);

  const { params, updateParams } = useQueryParams();

  const handleFilter = (filter: Filter) => {
    updateParams({
      ...filter,
      page : '1'
    });
  };

  const changePage = (value: number) => {
    if (value >= 1 && value <= lastPage) {
      updateParams({ page: value + "" });
    }
  };


  const [activeTab, setActiveTab] = useState(params.get("tab") || "Room");

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    let ignore = false;
    let activeFetches = 0;
  
    const page = params.get("page");
    const status = params.get("status") ?? "All";
    const semester = params.get("semester_id") ?? -1;
    const dateFrom = params.get("dateFrom");
    const dateTo = params.get("dateTo");
    const search = params.get("bnidOrEmail") ?? "";
    const room_id = params.getAll("rooms")

    if (semesters.length === 0) return;
  
    const fetchData = async () => {
      activeFetches += 1; // Increment active fetch count
      setLoading(true);
  
      const tab = params.get("tab") || activeTab;
      const apiUrl = tab === "Room" ? "borrowing/staff/pic" : "asset-borrowing/staff/pic";
  
      const roomParams = {
        initial: userStore.data?.UserName,
        semester_id: semester,
        status: status,
        page: page ?? 1,
        date_start: dateFrom,
        date_end: dateTo,
        search: search,
        room_id: room_id,
      };
  
      const assetParams = {
        pic: userStore.data?.UserName,
        status: status,
        semester_id: semester,
        page: page ?? 1,
        date_start: dateFrom,
        date_end: dateTo,
        sort: undefined,
        search: search,
      };
  
      const param = tab === "Room" ? roomParams : assetParams;
  
      try {
        const res = await axios.get(import.meta.env.VITE_API_URL + apiUrl, {
          params: param,
          signal,
        });
  
        if (ignore) return;
  
        let obj;
        if (tab === "Room") {
          obj = res.data;
          setData(obj.data ?? []);
        } else {
          obj = res.data.data;
          setData2(obj.data ?? []);
        }
  
        if (page === null || page != obj.current_page) {
          updateParams({
            page: obj.current_page,
          });
        }
        setLastPage(obj.last_page);
      } catch (error) {
        if (!signal.aborted) {
          console.log(error);
          setData([]);
        }
      } finally {
        activeFetches -= 1; // Decrement active fetch count
        if (activeFetches === 0) {
          setLoading(false); // Only set loading to false if no active requests remain
        }
      }
    };

    fetchData();
  
    return () => {
      ignore = true;
      controller.abort(); 
    };
  }, [params, semesters]);

  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName);
    updateParams({ tab: tabName, page: "1" });
  };

  const handleClearFilters = () => {
    updateParams({
      status: "All",
      dateFrom: "",
      dateTo: "",
      page: "1",
      bnidOrEmail : "",
      rooms:[],
      semester_id:"-1"
    });
  };

  return (
    <div className="container mx-auto py-10 px-8">
      <div className="text-lg">Borrowing History</div>
      <div className="flex gap-4 my-4">
        <Button
          variant="ghost"
          className={`btn shadow-lg shadow-secondary px-3 py-2 rounded text-sm font-medium border-2 border-transparent ${activeTab === "Room" ? 'border-blue-500' : ''}`}
          onClick={() => handleTabChange('Room')}
        >
          Room
        </Button>
        <Button
          variant="ghost"
          className={`btn shadow-lg shadow-secondary px-3 py-2 rounded text-sm font-medium border-2 border-transparent ${activeTab === "Asset" ? 'border-blue-500' : ''}`}
          onClick={() => handleTabChange('Asset')}
        >
          Asset
        </Button>
      </div>
      <FilterComponent
        handleClearFilter={handleClearFilters}
        isRoom = {activeTab === "Room" ? true : false}
        handleFilter={handleFilter}
        semeseters={semesters}
        rooms={rooms}
        isBNID={true} 
        activeTab={activeTab}
      />
      {activeTab === 'Room' ? (
        <BorrowingTable
          data={data as IBorrowingHistory[]}
          loading={loading}
          onChangePage={changePage}
          current_page={+(params.get("page") ?? 1)}
          last_page={lastPage}
          detailUrl="/history/staff/"
        />
      ) : (
        <AssetBorrowingTable
          data={data2 as IAssetBorrowingHistory[]}
          onChangePage={changePage}
          current_page={+(params.get("page") ?? 1)}
          last_page={lastPage}
          loading={loading}
          detailUrl="/history/asset/staff/"
        />
      )}
    </div>
  );
};

export default StaffHistory;
