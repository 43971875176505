import { FilterModal } from "@/components/modal/filter/FilterModal";
import { Option } from "@/components/multi-select-filter/MultiSelectFilter";
import { Button } from "@/components/ui/button/Button";
import { RangeDatePicker } from "@/components/ui/calendar/RangeDatePicker";
import { Input } from "@/components/ui/input/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select/Select";
import { cn, dateQuery, getStatusClass } from "@/lib/utils";
import { IRoom } from "@/models/room/Room";
import ISemester from "@/models/semester/Semester";
import { SelectValue } from "@radix-ui/react-select";
import { FormEvent, useState } from "react";

export type Filter = {
  rooms: number[];
  dateFrom: Date | string | undefined;
  dateTo: Date | string | undefined;
  semester_id: number;
  status: string;
  bnidOrEmail?: string; 
};

type RangeDate = {
  from: Date | undefined;
  to: Date | undefined;
};

type Props = {
  handleClearFilter: () => void;
  handleFilter: (filter: Filter) => void;
  semeseters: ISemester[];
  rooms: IRoom[];
  isRoom: boolean;
  isBNID?: boolean; 
  activeTab? : string;
};

const roomStatus = ["Accepted", "Finalized-Present", "Finalized-Absent", "Rejected", "Finalized-Canceled", "Canceled", "Pending"];
const assetStatus = ["Accepted", "Rejected", "Finalized", "Canceled", "Pending"];

const FilterComponent = (props: Props) => {
  const statuses = props.activeTab === "Room" ? roomStatus : assetStatus;
  const [selectedRoom, setSelectedRoom] = useState<Option[]>([]);
  const [semester, setSemester] = useState(-1);
  const [status, setStatus] = useState("All");
  const [dateRange, setDateRange] = useState<RangeDate>({
    from: undefined,
    to: undefined,
  });
  const [bnidOrEmail, setBnidOrEmail] = useState(''); // New state for BNID/Email

  const getSelectedIds = () => {
    return selectedRoom.map(element => +element.id);
  };

  const getOptions = () => {
    return props.rooms.map(element => ({
      id: element.id + "",
      name: element.room_code,
    }));
  };

  const handleFilter = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.handleFilter({
      rooms: getSelectedIds(),
      dateFrom: dateRange.from ? dateQuery(dateRange.from) : undefined,
      dateTo: dateRange.to ? dateQuery(dateRange.to) : undefined,
      semester_id: semester,
      status: status,
      bnidOrEmail: bnidOrEmail, 
    });
  };

  const handleClearFilters = () => {
    setSelectedRoom([]);
    setSemester(-1);
    setStatus("All");
    setDateRange({
      from: undefined,
      to: undefined,
    });
    setBnidOrEmail(''); 
    props.handleClearFilter();
  };

  return (
    <form
      onSubmit={handleFilter}
      className="flex flex-col items-center justify-center lg:flex-row lg:items-center lg:justify-between lg:p-2"
    >
      <div className="flex flex-col lg:flex-row items-center lg:items-end w-full">
        {props.isBNID && (
            <div className="p-2 min-w-fit w-full">
              <Input value={bnidOrEmail} onChange={(e) => setBnidOrEmail(e.target.value)} placeholder='BNID / Email' />
            </div>
          )}
        {props.isRoom && (
          <div className="flex flex-col gap-2 p-2 w-full lg:w-1/4">
            <FilterModal
              onSelectOption={(selected) => { setSelectedRoom(selected) }}
              selected={selectedRoom}
              options={[...getOptions()]}
              title="Select Rooms"
              triggerText="Room"
              onSubmit={() => {}}
            />
          </div>
        )}
        <div className="p-2 w-full lg:w-52">
          <Select value={status} onValueChange={(v) => setStatus(v)}>
            <SelectTrigger>
              <SelectValue placeholder="Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="All">All Status</SelectItem>
              {statuses.map((s , i) => (
                <SelectItem
                  value={s}
                  key={i}
                  className={cn("mb-1", getStatusClass(s))}
                >
                  {s}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="p-2 w-full">
          <Select value={semester + ""} onValueChange={(v) => setSemester(+v)}>
            <SelectTrigger>
              <SelectValue placeholder="Semester" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="-1">All Semesters</SelectItem>
              {props.semeseters.map((s) => (
                <SelectItem value={s.id + ""} key={s.id} className="mb-1">
                  {s.description}
                  {s.status === "1" && <>&nbsp;(Current)</>}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col gap-2 p-2 w-full">
          <RangeDatePicker selected={dateRange} onChange={setDateRange} className="w-full lg:w-64" />
        </div>
        
      </div>
      <div className="w-full flex justify-end gap-2">
        <Button
          type="button"
          onClick={handleClearFilters}
          variant="secondary"
          size="sm"
        >
          Clear Filters
        </Button>
        <Button
          type="submit"
          className="bg-blue-400 text-white"
          variant="outline"
          size="sm"
        >
          Filter
        </Button>
      </div>
    </form>
  );
};

export default FilterComponent;
