
import { BorrowingDetail, BorrowingDetailActions } from "@/components/borrowing/detail/BorrowingDetail";
import IBorrowingData from "@/models/borrowing/BorrowingData";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CancelDialog } from "../../staff_history/components/dialog/CancelDialog";
import LoadingIndicator from "@/components/ui/loading/LoadingIndicator";


const StudentHistoryDetail = () => {
  const params = useParams();
  const [data, setData] = useState<IBorrowingData>();
  const [loading, setLoading] = useState<any>(false);
  
  const fetchData = async () => {
    const id = params.id;
    if (id) {
      try {
        const res = await axios.get(
          `${import.meta.env.VITE_API_URL}borrowing/data`,
          {
            params: { id },
          }
        );
        setData(res.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [params.id]);

  const handleCancel = async (reason: string, reason_type: string) => {
    setLoading(true)
    try {
      await axios.patch(
        `${import.meta.env.VITE_API_URL}borrowing/student/cancel`,
        { id: data?.id.toString(), reason: reason, reason_type: reason_type }
      );
      setLoading(false)
      fetchData();
    } catch (error) {
      console.error("Error cancelling borrowing:", error);
    }
  };

  return (
    <>
    <LoadingIndicator loading={loading}/>
      <BorrowingDetail data={data}>
        {data?.borrowing_status === "Pending" && (
        <BorrowingDetailActions>
          <CancelDialog onCancel={handleCancel} />
        </BorrowingDetailActions>
      )}
      </BorrowingDetail>
    </>
  );
};

export default StudentHistoryDetail;
