import { useRoomBorrowingStore } from "@/store/room_borrowing/roomBorrowingStore";
import { useState, useEffect } from "react";
import { useRoomBorrowingNext } from "../RoomBorrowing";
import { IRoom } from "@/models/room/Room";
import axios from "axios";
import LabSelect from "../../components/laboratory/LabSelect";
import RoomSelect from "../../components/rooms/RoomSelect";
import { IRoomType } from "@/models/room/RoomType";
import { Option } from "@/components/multi-select-filter/MultiSelectFilter";

const ChooseRooms = () => {
  const [rooms, setRooms] = useState<IRoom[]>([]);
  const [selectedType, setSelectedType] = useState<number>(-1)
  const [roomTypes, setRoomTypes] = useState<IRoomType[]>([])
  const [loading, setLoading] = useState(false);
  const store = useRoomBorrowingStore();
  const { setRoomBorrowingNext } = useRoomBorrowingNext();

  const [selectedSoftware, setSelectedSoftware] = useState<Option[]>([]);

  const handleSoftwareChange = (selected: Option[]) => {
    setSelectedSoftware(selected);
  };

  useEffect(() => {
    const selectedRoom = store.data.room;
    if (selectedRoom !== undefined && selectedRoom > 0) {
      setRoomBorrowingNext(true);
    } else {
      setRoomBorrowingNext(false);
    }
  }, [store.data.room]);

  const onSelect = (room: IRoom) => {
    store.update("room", room.id);
    store.update("room_type", room.type?.id)
    store.update("room_code", room.room_code);
  };

  const onSelectType = (id: number) => {
    store.update("room_type", id);
    setSelectedType(id);
  }

  const fetchData = async (
    softwares: any[],
    ignore: boolean
  ) => {
    setLoading(true)
    let softwareIds = []
    if(softwares.length > 0){
       softwareIds = softwares.map(software => software.id);
    }
    try {
      const res = await axios.get(
        import.meta.env.VITE_API_URL + "room/borrowing",
        {
          params: {
            room_location_id: store.data.location,
            room_type_id: selectedType === -1 ? undefined : selectedType,
            softwares: softwareIds ?? []
          },
          withCredentials: true,
        }
      );
      if (ignore === true) return;
      setRooms(res.data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  };

  useEffect(() => {
    if (store.data.location === -1) return;
    let ignore = false;

    fetchData(selectedSoftware, ignore);
    return () => {
      ignore = true;
    };
  }, [store.data.location, store.data.room_type]);

  useEffect(() => {
    let ignore : boolean = false;
    if (store.data.location < 0) return;
    const fetchData = async () => {
      try {
        const res = await axios.get(
          import.meta.env.VITE_API_URL + "room-type/borrowing",
          {
            params: {
              room_location_id: store.data.location,
            },
            withCredentials: true,
          }
        );
        if (ignore === true) return;
        setRoomTypes(res.data.data);
      } catch (error) {
        setRoomTypes([]);
      }
    };
    fetchData();
    return () => {
        ignore = true;
    }
  }, [store.data.location]);

  const onSubmit = () => {
    fetchData(selectedSoftware, false);
  };

  return (
    <RoomSelect data={rooms} loading={loading} onSelect={onSelect} selected={store.data.room} selectedSoftware={selectedSoftware}
    onSoftwareChange={handleSoftwareChange} onSubmit={onSubmit}>
      <LabSelect
        data={roomTypes}
        hideAll={false}
        selected={selectedType}
        onSelect={onSelectType}
      />
    </RoomSelect>
  );
};

export default ChooseRooms;
