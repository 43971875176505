import ISemester from "@/models/semester/Semester"
import axios from "axios"
import { useEffect, useState } from "react"

const useSemesters = () => {
    const [semesters, setSemesters] = useState<ISemester[]>([])
    const [errors, setErrors] = useState<string>("")
    const fetchData = async (ignore: boolean) => {
        try {
            const res = await axios.get(`${import.meta.env.VITE_API_URL}semester/all`, {withCredentials: true});
            if (ignore === true) return;
            if (res.data.data) {
                setSemesters([...res.data.data.reverse()]);
            } else {
                setErrors("Data not found")
            }
        } catch (error) {
            axios.isAxiosError(error) ? setErrors(error.response?.data?.message) : setErrors("Unkown Error");
        }
    }
    useEffect(() => {
        let ignore = false;
        fetchData(ignore);
        return () => {
            ignore = true;
        }
    }, [])

    const refetch = () => {
        fetchData(false)
    }

    return {semesters, errors, refetch}
}

export default useSemesters