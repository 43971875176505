import React from "react";
import styles from "./Reaction.module.css";

type Props = {
  color: string;
  Icon: React.ElementType | null;
  onclick: (param: number) => void;
  id: number;
  selected: number;
};

const Reaction = ({ color, Icon, onclick, id, selected }: Props) => {
  return (
    <div
      className={styles.emojiCon}
      style={
        {
          "--hover-color": color,
        } as React.CSSProperties
      }
      onClick={() => onclick(id)}
    >
      {Icon && <Icon className={styles.emoji} style={selected === id ? { color: `var(--hover-color)` } : {}} />}
    </div>
  );
};

export default Reaction;
