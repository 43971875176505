import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "@/components/ui/table/Table";
  import { Button } from "@/components/ui/button/Button";
  import { motion } from "framer-motion";
  import { IAssetBorrowingHistory } from "@/models/borrowing/AssetBorrowingHistory";
  import { Link } from "react-router-dom";
  import SkeletonTable from "@/components/ui/skeleton/SkeletonTable";
  import { cn, dateFormat, getStatusClass } from "@/lib/utils";
import { IShift } from "@/models/shift/Shift";
  
  type Props = {
    current_page?: number;
    last_page?: number;
    onChangePage?: (value: number) => void;
    detailUrl: string;
    loading: boolean;
    data: IAssetBorrowingHistory[];
  };
  
  const AssetBorrowingTable = (props: Props) => {
    const handleChangePage = (change: number) => {
      const curr = props.current_page ?? 1;
      if (props.onChangePage) {
        props.onChangePage(curr + change);
      }
    };
  
    const formatDateShift = (date: string, shift: IShift) => {
      return `${dateFormat(date)} ${shift.shift_name}`;
    };

  
    return (
      <div className="w-full py-2 border-primary">
        <motion.div
          className="min-h-[20rem]"
          key={props.current_page}
          initial={{ opacity: 0.2, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            ease: "linear",
            duration: 0.4,
          }}
        >
          <Table>
            {props.loading === false && props.data.length === 0 && (
              <TableCaption className="text-lg py-4 h-[14rem] text-center">
                <div className="h-auto">No Borrowing Data yet...</div>
                <Link to={"/borrowing/student"} className="text-blue-400 underline">
                  Borrow Here
                </Link>
              </TableCaption>
            )}
            <TableHeader>
              <TableRow>
                <TableHead>Binusian ID</TableHead>
                <TableHead>Check-Out</TableHead>
                <TableHead>Check-In</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Action</TableHead>
              </TableRow>
            </TableHeader>
            {props.loading === true ? (
              <SkeletonTable rows={10} columns={5} />
            ) : (
              <TableBody>
                {props.data?.map((d) => (
                  d && (
                    <TableRow key={d.id}>
                      <TableCell>{d.student_id} - {d.student_name}</TableCell>
                      <TableCell>{formatDateShift(d.check_out_date, d.check_out_shift)}</TableCell>
                      <TableCell>{formatDateShift(d.check_in_date, d.check_in_shift)}</TableCell>
                      <TableCell className={cn(getStatusClass(d.status), "font-semibold")}>
                        {d.status}
                      </TableCell>
                      <TableCell>
                        <Link to={`${props.detailUrl}${d.id}`}>
                          <Button variant={"outline"} className="hover:bg-blue-600/90 hover:text-white transition-colors ease-linear duration-200">
                            Detail
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  )
                ))}
              </TableBody>
            )}
          </Table>
        </motion.div>
        <div className="p-4 flex justify-between items-center bg-secondary">
          <span>
            Page {props.current_page ?? 1} of {props.last_page}
          </span>
          <div className="flex gap-2">
            <Button
              variant="outline"
              size="sm"
              disabled={(props.current_page ?? 1) === 1}
              onClick={() => handleChangePage(-1)}
            >
              Previous
            </Button>
  
            <Button
              variant="outline"
              size="sm"
              onClick={() => handleChangePage(+1)}
              disabled={(props.current_page ?? 1) === props.last_page}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    );
  };
  
  export default AssetBorrowingTable;
  