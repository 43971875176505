import { useTheme } from "@/components/light-dark-theme/ThemeProvider";
import React from "react";

interface ItemProps extends React.LiHTMLAttributes<HTMLDivElement> {
  isSelected: boolean;
}

const GridItem = (props: ItemProps) => {
  const {theme} = useTheme();
  const { isSelected, ...atributes } = props;
  return (
    <div
      className={`relative bg-secondary p-3 rounded-md shadow-sm mb-2 cursor-pointer transition-colors duration-300 text-muted-foreground hover:text-secondary hover:bg-muted-foreground ${atributes.className}`}
      style={{
        background: isSelected ? theme !== "dark" ? "#0F172A" : "#fffcfc" : "",
        color: isSelected ? theme !== "dark" ? "#FFFFFF" : "#000000" : "",
      }}
      onClick={atributes.onClick}
      {...atributes}
    >
      <span
        className={`absolute top-0 left-0 h-full w-1 rounded-l-md transition-all ease-linear duration-150 ${
          isSelected ? "bg-blue-500" : "bg-transparent"
        }`}
      />
      <div className="flex flex-col">{atributes.children}</div>
    </div>
  );
};

type Props = {
  header: string;
  children: React.ReactNode;
};

const Grid = (props: Props) => {
  return (
    <>
      <div className="text-base md:text-lg font-semibold mb-2">
        {props.header}
      </div>
      <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-2">
        {props.children}
      </div>
    </>
  );
};

export { Grid, GridItem };
