import { useEffect, useState } from "react";
import { useRoomBorrowingNext } from "../RoomBorrowing";
import { useRoomBorrowingStore } from "@/store/room_borrowing/roomBorrowingStore";
import axios from "axios";
import IAsset from "@/models/asset/Asset";
import { useSearchParams } from "react-router-dom";
import AssetSelect from "../../components/asset/AssetSelect";

const ChooseAssets = () => {
  const roomBorrowingStore = useRoomBorrowingStore();
  const { setRoomBorrowingNext } = useRoomBorrowingNext();
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setRoomBorrowingNext(true);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
        const signal = controller.signal;
        let ignore = false;

        const fetchData = async (p : number) => {
            setLoading(true);
            try {
                const res = await axios.get(
                    import.meta.env.VITE_API_URL + "borrowing/student/assets",
                    {
                        params: {
                            page: p,
                            rooms: [roomBorrowingStore.data.room],
                        },
                        signal, 
                    }
                );
                if (ignore) return;
                if (!ignore)
                  setLoading(false);

                const data = res.data.data;
                setLastPage(data.last_page ?? 1);
                setAssets(data.data);
                setCurrentPage(p);
            } catch (error) {
                if (!signal.aborted) { 
                    console.error(error);
                    setAssets([]);
                }
            } 
        };

        const page = getPage();

        if (roomBorrowingStore.data.room !== -1 && !ignore) {
          if (assets?.length <= 1 || page !== currentPage) {
              fetchData(page);
          }
      }

        return () => {
            ignore = true;
            controller.abort()
        };
  }, [roomBorrowingStore, searchParams]);

  const handleSelect = (asset: IAsset) => {
    const selected = roomBorrowingStore.data.assets;
    const isSelected = selected.some((a) => a.id === asset.id);
    const newSelection = isSelected
      ? selected.filter((a) => a.id !== asset.id)
      : [...selected, asset];
    roomBorrowingStore.update("assets", newSelection);
  };

  const getPage = () => {
    const page = searchParams.get("page");
    return page ? +page : 1;
  };

  const changePage = (page: number) => {
    if (page < 1 || page > lastPage) return;
    setSearchParams({ page: page + "" });
  };


  return (
    <div className={`w-full`}>
      <AssetSelect assets={assets} loading={loading} handleSelect={handleSelect} currentPage={getPage()} lastPage={lastPage} changePage={changePage} title="Room"/>
    </div>
  );
};

export default ChooseAssets;
