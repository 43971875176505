import React, { useState } from "react";
import devices from "@assets/images/devices.png";
import { Grid, GridItem } from "@/components/ui/grid/Grid";
import SkeletonGrid from "@/components/ui/skeleton/SkeletonGrid";
import IAsset from "@/models/asset/Asset";
import { useAssetBorrowingStore } from "@/store/asset_borrowing/assetBorrowingStore";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination/Pagination";
import { useRoomBorrowingStore } from "@/store/room_borrowing/roomBorrowingStore";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from "@/components/ui/dialog/Dialog"; 
import LoadingIndicator from "@/components/ui/loading/LoadingIndicator";
import NotFound from "/images/notfound.jpg"

interface AssetSelectProps {
  assets: IAsset[];
  loading: boolean;
  handleSelect: (asset: IAsset) => void;
  currentPage: number;
  lastPage: number;
  changePage: (page: number) => void;
  title: string;
}

const AssetSelect: React.FC<AssetSelectProps> = ({
  assets,
  loading,
  handleSelect,
  currentPage,
  lastPage,
  changePage,
  title
}) => {
  const assetBorrowingStore = useAssetBorrowingStore();
  const roomBorrowingStore = useRoomBorrowingStore();

  // const [assetImages, setAssetImages] = useState<{ [key: number]: string }>({});
  const [loadingImage, setLoadingImage] = useState<number | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<string | null>(null);
  const [imageLoading, setImageLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   if (title === "Asset") {
  //     assets.forEach(async (asset) => {
  //       try {
  //         const response = await axios.get(
  //           `${import.meta.env.VITE_API_URL}asset-borrowing/image-borrowable-asset?id=${asset.id}`
  //         );
  //         setAssetImages((prevState) => ({
  //           ...prevState,
  //           [asset.id]: response.data?.data[0]?.path,
  //         }));
  //       } catch (error) {
  //         // console.error("Error fetching image:", error);
  //       }
  //     });
  //   }
  // }, [assets, title]);

  const handleViewImage = async (assetId: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); 
    setLoadingImage(assetId);
    setImageLoading(true); 
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}asset-borrowing/image-borrowable-asset?id=${assetId}`
      );
      setCurrentImage(response.data?.data[0]?.path);
      setImageLoading(false);
      setDialogOpen(true); 
    } catch (error) {
      console.error("Error fetching image:", error);
    } finally {
      setLoadingImage(null);
    }
  };

  return (
    <>
      {loading ? (
        <SkeletonGrid count={20} />
      ) : (
        <Grid header="Choose Asset">
          {assets?.length === 0 && <div>No Asset</div>}
          {assets?.map((asset) => (
            <GridItem
              isSelected={
                title === "Room"
                  ? roomBorrowingStore.data.assets.some((a) => a.id === asset.id)
                  : assetBorrowingStore.data.assets.some((a) => a.id === asset.id)
              }
              key={asset.id}
              onClick={() => handleSelect(asset)}
            >
              <div className="w-full flex flex-col gap-2">
                {title !== "Asset" && (<img
                  src={asset.images ? asset.images[0]?.path : devices}
                  className="w-20 h-20 md:w-24 md:h-24 rounded-md"
                />)}

                <div className="flex flex-col truncate">
                  <div className="font-semibold text-lg">{asset.name}</div>
                  <div className="text-sm">ID: {asset.id}</div>
                  <div className="text-xs"> {asset.year}</div>
                  <div className="text-xs">{asset.description}</div>
                  {title === "Asset" && (
                    <button
                      onClick={(event) => handleViewImage(asset.id, event)}
                      className="mt-2 p-1 bg-blue-500 text-sm text-white rounded text-center w-fit"
                    >
                      {loadingImage === asset.id ? "Loading..." : "View Image"}
                    </button>
                  )}
                </div>
              </div>
            </GridItem>
          ))}
        </Grid>
      )}

      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious onClick={() => changePage(currentPage - 1)} />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink>
              {currentPage} / {lastPage}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationNext onClick={() => changePage(currentPage + 1)} />
          </PaginationItem>
        </PaginationContent>
      </Pagination>

      {/* Image View Dialog */}
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Image</DialogTitle>
            <DialogClose />
          </DialogHeader>
          <div className="flex justify-center p-4">
          {imageLoading ? ( 
              <LoadingIndicator loading={imageLoading}/> 
            ) :   (
              <img
                src={currentImage !== null && currentImage !== undefined ? currentImage : NotFound}
                alt="Asset"
                className="max-w-full max-h-full"
                onLoad={() => setImageLoading(false)}
                onError={() => {
                  setImageLoading(false); 
                  setCurrentImage(null); 
                }}
              />
            ) }
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssetSelect;
