import React from "react";
import styles from "./ImageCard.module.css";
import { Link } from "react-router-dom";

interface CardProps {
  title: string;
  imageUrl: string;
  destUrl: string;
}

const ImageCard: React.FC<CardProps> = ({ title, imageUrl, destUrl }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardImg} style={{ backgroundImage: `url(${imageUrl})` }}></div>
      <Link to={destUrl} className={styles.cardLink}>
        <div className={styles.cardImgHover} style={{ backgroundImage: `url(${imageUrl})` }}></div>
      </Link>
      <div className={styles.cardInfo}>
        <h3 className={styles.cardTitle}>{title}</h3>
      </div>
    </div>
  );
};

export default ImageCard;
