import NavBar from "../navigation/header/NavBar";
import { Outlet } from "react-router-dom";
import { Toaster } from "@/components/ui/toast/Toaster";
import { useEffect } from "react";
import { useUserStore } from "@/store/user/userStore";

const MainLayout = () => {
  const userStore = useUserStore();
  useEffect(() => {
    userStore.getData();  
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <NavBar />
      <div className="flex flex-1 w-full">
        <Outlet />
      </div>
      <Toaster />
    </div>
  );
};

export default MainLayout;
