import React from 'react';
import { useTheme } from './ThemeProvider';
import { CiLight, CiDark } from "react-icons/ci"

const ThemeToggle: React.FC = () => {
  const { theme, setTheme } = useTheme();

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  return (
    <button onClick={toggleTheme} className="p-2 w-fit text-lg rounded hover:text-primary transition-all ease-linear focus:outline-none focus:ring" aria-label="Toggle Theme">
      {theme === 'dark' ? <CiLight/> : <CiDark/>}
    </button>
  );
};

export default ThemeToggle;
