import React from 'react';

type RoomTransactionsProps = {
  transactions: any[];
};

const RoomTransactions: React.FC<RoomTransactionsProps> = ({ transactions }) => {
  const totalShifts = 6;
  const shifts = Array.from({ length: totalShifts }, (_, index) => index + 1);
  return (
    <>
      <h3 className="font-semibold text-lg">Room Transactions</h3>

      <div className="flex justify-between">
        {shifts.map((shift) => {
            const transaction = transactions.find((t) => t.id === shift);

            return (
              <div
                key={shift}
                className={`font-semibold w-full text-center border-gray-400 border p-2 md:text-md ${
                  transaction ? 'text-green-600' : 'text-red-600'
                }`}
              >
                {`Shift ${shift}`} 
              </div>
            );
          })}
      </div>
    </>
  );
};

export default RoomTransactions;
