import { IRoom } from "@/models/room/Room"
import axios from "axios"
import { useEffect, useState } from "react"

const useRoomByPic = (initial: string | undefined) => {
    const [rooms, setRooms] = useState<IRoom[]>([])
    const [status, setStatus] = useState<string>("")

    const fetchData = async (ignore: boolean) => {
        setStatus("loading")
        try {
            const res = await axios.get(`${import.meta.env.VITE_API_URL}room/borrowing-pic`, {
                params: {
                    pic: initial,
                  },
                withCredentials: true,
            })
            if (ignore) {
                setStatus("success");
                return;
            }
            if (res.data.data) {
                setStatus("success");
                setRooms(res.data.data);
            } else {
                setStatus("error")
            }
        } catch (error) {
            setStatus("error")
        }
    }

    useEffect(() => {
        let ignore = false;
        if (!initial || initial === "") {
            return;
        }
        fetchData(ignore)
        return () => {
            ignore = true;
        }
    }, [initial])


    return {rooms, status}
}

export default useRoomByPic