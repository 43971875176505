import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { User } from './Columns'; 
import UserTable from '@/components/usermanagement/UserTable';
import { Input } from '@/components/ui/input/Input';
import { Button } from '@/components/ui/button/Button';
import useQueryParams from '@/lib/hooks/useQueryParams';
import { BanDialog } from './components/BanDialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select/Select';

const UserFilterComponent = ({ onFilter, onClearFilters }: { onFilter: (filters: any) => void; onClearFilters: () => void; }) => {
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');

  const handleFilter = () => {
    onFilter({ search, status });
  };

  return (
    <div className="flex items-center justify-between p-4">
      <div className='flex'>
        <div className="flex flex-col gap-2 p-4 w-[20rem]">
          <Input value={search} onChange={(e) => setSearch(e.target.value)} placeholder='BNID / Name' />
        </div>
        <div className="flex flex-col gap-2 p-4">
          <Select onValueChange={setStatus} value={status}>
            <SelectTrigger className='w-[10em]'>
              <SelectValue placeholder='Status' />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Active">Active</SelectItem>
              <SelectItem value="Banned">Banned</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="flex space-x-2">
        <Button
          type="button"
          onClick={() => {
            setSearch('');
            setStatus('');
            onClearFilters}}
          variant="secondary"
          size="sm"
        >
          Clear Filters
        </Button>
        <Button className='bg-blue-400' variant='outline' size='sm' onClick={handleFilter}>Filter</Button>
      </div>
    </div>
  );
};


const UserManagementPage: React.FC = () => {
  const [data, setData] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [actionType, setActionType] = useState<"ban" | "unban">("ban"); // State to manage action type
  const { params, updateParams } = useQueryParams();

  const handleFilter = (filter: { search: string; status: string }) => {
    updateParams({ ...filter, page: '1' });
  };

  const changePage = (value: number) => {
    if (value >= 1 && value <= lastPage) {
      updateParams({ page: value.toString() });
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const page = params.get("page") || "1";
      const search = params.get("search") || "";
      const status = params.get("status") || "";

      const res = await axios.get(import.meta.env.VITE_API_URL + 'student/paginate', {
        params: {
          page,
          search,
          status,
        },
      });

      const fetchedData = res.data.data.students.map((item : any) => ({
        id: item.id.toString(),
        bnid: item.username,
        email: item.name,
        status: item.status,
      }));

      setData(fetchedData);
      setLastPage(res.data.data.last_page);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [params]);

  const handleStatusToggle = async (banReason: string) => {
    if (!selectedUser) return;
    console.log(selectedUser)
    console.log({
      student_id: selectedUser.id,
      username: selectedUser.bnid,
      ban_reason: banReason
    })

    try {
      const response = await axios.patch(import.meta.env.VITE_API_URL + 'ban/student-ban-toggle', null, {
        params: {
          student_id: selectedUser.id,
          username: selectedUser.bnid,
          ...(selectedUser.status !== "Banned" && { ban_reason: banReason })
        }
      });

      if (response.data.status) {
        await fetchData();
      } else {
        console.error("Failed to update status.");
      }
    } catch (error) {
      console.error("Error toggling status:", error);
    }

    setShowDialog(false);
    setSelectedUser(null);
  };

  const openBanDialog = (user: User, action: "ban" | "unban") => {
    setSelectedUser(user);
    setActionType(action);
    setShowDialog(true);
  };

  const handleClearFilters = () => {
    updateParams({ search: '', status: '', page: '1' }); 
    fetchData(); 
  };


  return (
    <div className="container mx-auto py-10 px-8">
      <h1 className="text-lg mb-4">User Management</h1>
      <UserFilterComponent onFilter={handleFilter} onClearFilters={handleClearFilters}/>
      <UserTable
        data={data}
        loading={loading}
        currentPage={+(params.get("page") ?? 1)}
        lastPage={lastPage}
        onChangePage={changePage}
        onStatusToggle={(user) => openBanDialog(user, user.status === "Active" ? "ban" : "unban")} // Determine action based on status
      />
      <BanDialog
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        onConfirm={(reason) => handleStatusToggle(reason)}
        actionType={actionType} // Pass action type to BanDialog
      />
    </div>
  );
};

export default UserManagementPage;
