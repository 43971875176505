// components/ui/table/UserTable.tsx
import React from 'react';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table/Table";
import { Button } from "@/components/ui/button/Button";
import SkeletonTable from "@/components/ui/skeleton/SkeletonTable";
import { cn, getStatusClass } from "@/lib/utils";

interface User {
  id: string;
  bnid: string;
  email: string;
  status: string;
}

interface Props {
  currentPage?: number;
  lastPage?: number;
  onChangePage?: (value: number) => void;
  loading: boolean;
  data: User[];
  onStatusToggle: (user: User) => void;
}

const UserTable: React.FC<Props> = ({ currentPage, lastPage, onChangePage, loading, data, onStatusToggle }) => {
  const handleChangePage = (change: number) => {
    const curr = currentPage ?? 1;
    if (onChangePage) {
      onChangePage(curr + change);
    }
  };

  return (
    <div className="w-full py-2">
      <Table>
        {loading === false && data.length === 0 && (
          <TableCaption className="text-lg py-4">
            <div>No User Data yet...</div>
          </TableCaption>
        )}
        <TableHeader>
          <TableRow>
            <TableHead>BNID</TableHead>
            <TableHead>Name</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
        </TableHeader>
        {loading ? (
          <SkeletonTable rows={10} columns={4} />
        ) : (
          <TableBody>
            {data.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.bnid}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell className={cn(getStatusClass(user.status), "font-semibold")}>{user.status}</TableCell>
                <TableCell>
                  <Button
                    variant={user.status === "Active" ? "destructive" : "default"}
                    onClick={() => onStatusToggle(user)}
                    className="w-[6rem]"
                  >
                    {user.status === "Active" ? "Deactivate" : "Activate"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      <div className="p-4 flex justify-between items-center bg-secondary">
        <span>
          Page {currentPage ?? 1} of {lastPage}
        </span>
        <div className="flex gap-2">
          <Button
            variant="outline"
            size="sm"
            disabled={(currentPage ?? 1) === 1}
            onClick={() => handleChangePage(-1)}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => handleChangePage(1)}
            disabled={(currentPage ?? 1) === lastPage}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
