"use client";
import styles from "../Navigation.module.css";
import { IoLogIn, IoLogOut } from "react-icons/io5";
import StudentNav from "./student/StudentNav";
import StaffNav from "./staff/StaffNav";
import { useState } from "react";
import { GrClose } from "react-icons/gr";
import LogoLCAS from "@/pages/main/components/logo/LogoLCAS";
import { Link, useNavigate } from "react-router-dom";
import { useUserStore } from "@/store/user/userStore";
import ThemeToggle from "@/components/light-dark-theme/ThemeToggle";
import { cn } from "@/lib/utils";
import { useTheme } from "@/components/light-dark-theme/ThemeProvider";
import { Button } from "@/components/ui/button/Button";
import GuestNav from "./guest/GuestNav";
import { useMsal } from "@azure/msal-react";


const LogoutButton = (props: { className?: string }) => {
  const navigate = useNavigate();
  const userStore = useUserStore();
  const handleLogout = async () => {
    const res = await userStore.logout();
    if (res) {
      navigate("/login");
    }
  };
  return (
    <div className={styles.navLink} onClick={handleLogout}>
      <IoLogOut className={cn("w-6 h-6 text-red-500", props.className)} />
    </div>
  );
};

const LoginButton = (props: { className?: string }) => {
  return (
    <Link className={styles.navLink} to={"/login"}>
      <IoLogIn className={cn("w-6 h-6 text-primary", props.className)} />
    </Link>
  );
};

const MicrosoftLogoutButton = (props: { className?: string }) => {
  const { instance } = useMsal();
  const navigate = useNavigate()
  const userStore = useUserStore();

  const handleMicrosoftLogout = async () => {
    const res = await userStore.logout();
    if(res){
      instance
        .logoutRedirect()
        .then(async () => {
          navigate('/login');
        })
        .catch((error) => {
          console.error('Logout failed', error);
        });
    }
  };

  return (
    <div className={styles.navLink} onClick={handleMicrosoftLogout}>
      <IoLogOut className={cn("w-6 h-6 text-red-500", props.className)} />
    </div>
  );
};


const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();
  const userStore = useUserStore();
  const { instance } = useMsal();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isMicrosoftUser = userStore.data && userStore.data.Role === "Student" && instance;

  return (
    <div className="flex flex-col items-center w-full pt-2">
      <div
        className={cn(
          "relative z-50 h-16 flex items-center w-5/6 lg:px-8 md:px-6 shadow-md px-2 py-1 rounded-[2.5rem] border bg-background/85",
          theme === "dark" ? "shadow-gray-700" : "shadow-gray-300"
        )}
      >
        <div className={"px-4"}>
          <Link to={"/home"}>
            <LogoLCAS />
          </Link>
        </div>
        <div className="hidden md:flex justify-between items-center flex-1 h-16">
          <div className="flex items-end flex-1 gap-4 lg:gap-6">
          {
            userStore.data == undefined || !userStore.data ? (
              <GuestNav />
            ) : userStore.data.Role === "Student" ? (
              <StudentNav />
            ) : (
              <StaffNav />
            )
          }
          </div>
          <div className="flex items-center justify-center">
            {userStore.data ? (
              <>
                <div className="px-2 text-end whitespace-nowrap">
                  Welcome,&nbsp;
                  <span className="text-blue-500 font-semibold">
                    {userStore.data?.Name}
                  </span>
                </div>
                <ThemeToggle />
                {/* <LogoutButton /> */}
                {isMicrosoftUser ? <MicrosoftLogoutButton /> : <LogoutButton/>}
              </>
            ) : (
              <>
                <div className="px-2 text-end whitespace-nowrap font-semibold">
                  You are not logged in
                </div>
                <ThemeToggle />
                <LoginButton />
              </>
            )}
          </div>
        </div>
        <div className="flex justify-between items-start py-3 px-2 flex-1 h-16 md:hidden">
          <div
            className={cn("rounded-3xl z-50", isOpen && "h-auto p-2 bg-secondary")}
          >
            <Button
              onClick={toggleMenu}
              className={cn("p-0 rounded-3xl relative")}
              variant={isOpen ? "secondary" : "default"}
            >
              <span
                className={cn(
                  "w-full flex justify-center text-base text-white transition-all ease-linear duration-150 absolute",
                  isOpen ? "opacity-0 -left-4" : "opacity-100 left-0"
                )}
              >
                Menu
              </span>
              <div
                className={
                  "w-full px-4 flex justify-start absolute text-red-500 transition-all ease-linear duration-150  " +
                  (isOpen ? "opacity-100 right-0" : "opacity-0 -right-20")
                }
              >
                <GrClose className={cn("text-lg w-4 h-4")} />
              </div>
            </Button>
            {isOpen && (
              <div className="flex flex-col flex-1 gap-2 px-2 text-white">
                {
                  userStore.data == undefined || !userStore.data ? (
                    <GuestNav />
                  ) : userStore.data.Role === "Student" ? (
                    <StudentNav />
                  ) : (
                    <StaffNav />
                  )
                }
              </div>
            )}
          </div>
          <div className="flex items-center px-2">
            <ThemeToggle />
            <Button
              variant={userStore.data === undefined ? "default" : "destructive"}
              className="px-2 w-12 rounded-3xl rela"
            >
              {userStore.data === undefined ? (
                <LoginButton className="text-white" />
              ) : (
                <LogoutButton className="text-white" />
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
