import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table/Table";
import { Button } from "@/components/ui/button/Button";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import SkeletonTable from "@/components/ui/skeleton/SkeletonTable";
import { dateFormat } from "@/lib/utils";
import { Feedback } from "@/models/feedback/Feedback";

type Props = {
  current_page?: number;
  last_page?: number;
  onChangePage?: (value: number) => void;
  loading: boolean;
  data: Feedback[];
};

const FeedbackTable = (props: Props) => {
  const handleChangePage = (change: number) => {
    const curr = props.current_page ?? 1;
    if (props.onChangePage) {
      props.onChangePage(curr + change);
    }
  };

  return (
    <div className="w-full py-2">
      <motion.div
        key={props.current_page}
        initial={{ opacity: 0.2, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          ease: "linear",
          duration: 0.4,
        }}
      >
        <Table>
          {props.loading === false && props.data.length === 0 && (
            <TableCaption className="text-lg py-4">
              <div>No Feedback Data yet...</div>
              <Link to={"/borrowing/student"} className="text-blue-400 underline">
                Borrow Here
              </Link>
            </TableCaption>
          )}
          <TableHeader>
            <TableRow>
              <TableHead>Binusian ID</TableHead>
              <TableHead>Message</TableHead>
              <TableHead>Category</TableHead>
              <TableHead>Satisfaction (1-5)</TableHead>
              <TableHead>Date</TableHead>
            </TableRow>
          </TableHeader>
          {props.loading === true ? (
            <SkeletonTable rows={10} columns={6} />
          ) : (
            <TableBody>
              {props.data.map((d) => (
                <TableRow key={d.id}>
                  <TableCell>{d.binusian_id}</TableCell>
                  <TableCell>{d.message}</TableCell>
                  <TableCell>{d.feedback_category.name}</TableCell>
                  <TableCell>{d.satisfaction}</TableCell>
                  <TableCell className="whitespace-nowrap">{dateFormat(d.created_at)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </motion.div>
      <div className="p-4 flex justify-between items-center bg-secondary">
        <span>
          Page {props.current_page ?? 1} of {props.last_page}
        </span>
        <div className="flex gap-2">
          <Button variant="outline" size="sm" disabled={(props.current_page ?? 1) === 1} onClick={() => handleChangePage(-1)}>
            Previous
          </Button>

          <Button variant="outline" size="sm" onClick={() => handleChangePage(+1)} disabled={(props.current_page ?? 1) === props.last_page}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackTable;
