import { Link } from "react-router-dom";
import styles from "../../Navigation.module.css";
const GuestNav = () => {
  return (
    <>
      <Link to={"/"} className={styles.navLink}>
        Home
      </Link>
    </>
  );
};

export default GuestNav;
