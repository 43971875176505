/**
 * CUSTOM PROVIDER
 * bisa dipakai buat axios, redux dsb
 */

import { ReactNode, useEffect } from "react";
import { useUserStore } from "./store/user/userStore";
import { ThemeProvider } from "./components/light-dark-theme/ThemeProvider";

type Props = {
  children: ReactNode;
};

const AppProvider = (props: Props) => {
  const userStore = useUserStore();
  useEffect(() => {
    if (!userStore) return;
    userStore.getData();
  }, []);

  return (
    <>
      <ThemeProvider defaultTheme="dark" storageKey="my-app-theme">
        <div className="font-lregular">{props.children}</div>
      </ThemeProvider>
    </>
  );
};

export default AppProvider;
