import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog/Dialog";
import { Input } from "@/components/ui/input/Input";
import { SyntheticEvent, useState } from "react";
import RoomTransactions from "../RoomTransactions";
import { AssetTransactions } from "../transactions/AssetTransactions";

type Props = {
  onAccept: (reason: string) => void;
  title?: string;
  transactions?: any;
  borrowingCurrent?: any;
  assetBorrowingCurrent?: any;
};

export function AcceptDialog(props: Props) {
  const [reason, setReason] = useState("");

  const handleAccept = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onAccept(reason);
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="blue">Accept</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Accept Borrowing</DialogTitle>
        </DialogHeader>

        { props.transactions !== null && props.transactions.length > 0 && (<RoomTransactions transactions={props.transactions}/>)}


          {props.title === "Asset" && (
            <AssetTransactions
              borrowingCurrent={props.borrowingCurrent}
              assetBorrowingCurrent={props.assetBorrowingCurrent}
          />
        )}

        <div className="text-lg font-semibold">
          Are you sure you want to accept this borrowing?
        </div>

        <Input
          placeholder="Note"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          className="mt-2"
        />
        <DialogFooter className="justify-end flex flex-row gap-4">
          <Button type="button" variant={"blue"} onClick={handleAccept}>
            Accept
          </Button>
          <DialogClose asChild>
            <Button type="button" variant={"destructive"}>
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
