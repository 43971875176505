import React from "react";
import styles from "./Type.module.css";

type Props = {
  color: string;
  text: string;
  onclick: (param: number) => void;
  id: number;
  selected: number;
};

const Type = ({ color, text, onclick, id, selected }: Props) => {
  return (
    <div
      className={styles.typeCon}
      style={
        {
          "--hover-color": color,
          border: selected === id ? `3px solid var(--hover-color)` : undefined,
        } as React.CSSProperties
      }
      onClick={() => onclick(id)}
    >
      <h4 className={styles.type} style={{ color: selected === id ? `var(--hover-color)` : undefined }}>
        {text}
      </h4>
    </div>
  );
};

export default Type;
