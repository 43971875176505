import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import MainLayout from "./layout/main/MainLayout";
import StudentHome from "@/pages/main/home/Home";
import StaffHome from "@/pages/staff/home/Home";
import Borrowing from "@/pages/main/borrowing/Borrowing";
import RoomBorrowing from "./pages/main/borrowing/room/RoomBorrowing";
import ChooseRooms from "./pages/main/borrowing/room/rooms/ChooseRooms";
import ChooseAssets from "./pages/main/borrowing/room/assets/ChooseAssets";
import ChooseDates from "./pages/main/borrowing/room/dates/ChooseDates";
import BorrowerData from "./pages/main/borrowing/room/data/BorrowerData";
import ChooseLocation from "./pages/main/borrowing/room/location/ChooseLocation";
import UserManagementPage from "./pages/staff/user/UserManagement";
import GuestLayout from "./layout/guest/GuestLayout";
import LoginPage from "./pages/auth/login/Login";
import StaffHistoryDetail from "./pages/main/staff_history/detail/StaffHistoryDetail";
import StaffHistory from "./pages/main/staff_history/StaffHistory";
import StudentHistory from "./pages/main/student_history/StudentHistory";
import SubmitBorrowing from "./pages/main/borrowing/room/submit/SubmitBorrowing";
import ChooseAssetLocation from "./pages/main/borrowing/asset/location/ChooseAssetLocation";
import ChooseAssetRooms from "./pages/main/borrowing/asset/rooms/ChooseAssetRooms";
import { useUserStore } from "./store/user/userStore";
import AssetBorrowing from "./pages/main/borrowing/asset/AssetBorrowing";
import ChooseAssetItems from "./pages/main/borrowing/asset/asset/ChooseAssetItems";
import AssetBorrowerData from "./pages/main/borrowing/asset/data/AssetBorrowerData";
import SubmitAssetBorrowing from "./pages/main/borrowing/asset/submit/SubmitAssetBorrowing";
import ChooseAssetDates from "./pages/main/borrowing/asset/date/ChooseAssetDates";
import ViewAllFeedback from "./pages/staff/feedback/ViewAllFeedback";
import FeedbackForm from "./pages/main/feedback/FeedbackForm";
import ProtectedLayout from "./layout/protected/ProtectedLayout";
import StudentHistoryDetail from "./pages/main/student_history/detail/StudentHistoryDetail";
import StudentAssetHistoryDetail from "./pages/main/student_history/detail/StudentAssetHistoryDetail";
import StaffAssetHistoryDetail from "./pages/main/staff_history/detail/StaffAssetHistoryDetail";
import StaffLayout from "./layout/protected/StaffLayout";

const AppRoute = () => {
  const userStore = useUserStore();
  return (
    <BrowserRouter>
      <Routes>
      <Route path="*" element={<Navigate to="/home" replace />} />
        <Route
          path="/login"
          element={
            <GuestLayout>
              <LoginPage />
            </GuestLayout>
          }
        />
        <Route
          path="/login/microsoft"
          element={
            <GuestLayout>
              <LoginPage />
            </GuestLayout>
          }
        />
        <Route element={<MainLayout />}>
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route
            path="/home"
            element={
              !userStore.data || userStore.data?.Role === "Student" ? (
                <StudentHome />
              ) : (
                <StaffHome />
              )
            }
          />

          <Route element={<ProtectedLayout />}>
            <Route path="/borrowing/student" element={<Borrowing />} />
            <Route path="/history">
              <Route element={<StaffLayout />}>
                <Route path="/history/staff/" element={<StaffHistory />} />
                <Route
                  path="/history/staff/:id"
                  element={<StaffHistoryDetail />}
                />
                <Route
                  path="/history/asset/staff/:id"
                  element={<StaffAssetHistoryDetail />}
                />
              </Route>
              <Route path="/history/student/" element={<StudentHistory />} />
              <Route path="/history/student/:id" element={<StudentHistoryDetail />} />
              <Route path="/history/asset/student/:id" element={<StudentAssetHistoryDetail />} />
            </Route>
            
            <Route
              path="/feedback"
              element={
                userStore.data?.Role !== "Assistant" ? (
                  <FeedbackForm />
                ) : (
                  <ViewAllFeedback />
                )
              }
            />

            <Route path="/borrowing/room" element={<RoomBorrowing />}>
              <Route
                path="/borrowing/room/campus"
                element={<ChooseLocation />}
              />
              <Route
                path="/borrowing/room/choose-room"
                element={<ChooseRooms />}
              />
              <Route
                path="/borrowing/room/choose-date"
                element={<ChooseDates />}
              />
              <Route
                path="/borrowing/room/choose-asset"
                element={<ChooseAssets />}
              />
              <Route
                path="/borrowing/room/borrower-data"
                element={<BorrowerData />}
              />
              <Route
                path="/borrowing/room/submit"
                element={<SubmitBorrowing />}
              />
            </Route>
            <Route path="/borrowing/asset" element={<AssetBorrowing />}>
              <Route
                path="/borrowing/asset/campus"
                element={<ChooseAssetLocation />}
              />
              <Route
                path="/borrowing/asset/choose-room"
                element={<ChooseAssetRooms />}
              />
              <Route
                path="/borrowing/asset/choose-asset"
                element={<ChooseAssetItems />}
              />
              <Route
                path="/borrowing/asset/choose-date"
                element={<ChooseAssetDates />}
              />
              <Route
                path="/borrowing/asset/borrower-data"
                element={<AssetBorrowerData />}
              />
              <Route
                path="/borrowing/asset/submit"
                element={<SubmitAssetBorrowing />}
              />
            </Route>
            <Route element={<StaffLayout />}>
              <Route path="/usermanagement" element={<UserManagementPage />} />
            </Route>

          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
