import { useEffect, useState } from "react";
import { List, ListItem } from "@/components/ui/list/List";
import axios from "axios";
import { IRoomLocation } from "@/models/room/RoomLocation";
import SkeletonList from "@/components/ui/skeleton/SkeletonList";
import { useAssetBorrowingStore } from "@/store/asset_borrowing/assetBorrowingStore";
import { useAssetBorrowingNext } from "../AssetBorrowing";

const ChooseAssetLocation = () => {
  const [locations, setLocations] = useState<IRoomLocation[]>([]);
  const [loading, setLoading] = useState(false);
  const assetBorrowingStore = useAssetBorrowingStore();
  const { setAssetBorrowingNext } = useAssetBorrowingNext();

  const handleSelected = (loc: IRoomLocation) => {
    assetBorrowingStore.update("location", loc.id);
    assetBorrowingStore.update("room_type", -1);
  };

  useEffect(() => {
    const d = assetBorrowingStore.data.location;
    if (d !== undefined && d > 0) {
      setAssetBorrowingNext(true);
    } else {
      setAssetBorrowingNext(false);
    }
  }, [assetBorrowingStore.data.location]);

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          import.meta.env.VITE_API_URL + "room-location/asset-borrowing", {withCredentials: true,}
        );
        if (ignore === true) return;
        setLocations(res.data.data);
      } catch (error) {
        setLocations([]);
      }
      setLoading(false);
    };
    fetchData();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div className={`w-full`}>
      <List header="Choose one of Campus room location you want to borrow">
        {loading ? (
          <SkeletonList count={6} skeletonClassName="h-16" />
        ) : (
          <>
            {locations.length === 0 && <div>No Data</div>}
            {locations.map((location) => (
              <ListItem
                isSelected={location.id === assetBorrowingStore.data.location}
                key={location.id}
                onClick={() => handleSelected(location)}
              >
                {location.location}
              </ListItem>
            ))}
          </>
        )}
      </List>
    </div>
  );
};

export default ChooseAssetLocation;
