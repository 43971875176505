import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table/Table";
import { Button } from "@/components/ui/button/Button";
import { motion } from "framer-motion";
import { IBorrowingHistory } from "@/models/borrowing/BorrowingHistory";
import { Link } from "react-router-dom";
import SkeletonTable from "@/components/ui/skeleton/SkeletonTable";
import { cn, dateFormat, getStatusClass } from "@/lib/utils";

type Props = {
  current_page?: number;
  last_page?: number;
  onChangePage?: (value: number) => void;
  detailUrl: string,
  loading: boolean;
  data: IBorrowingHistory[];
};

const BorrowingTable = (props: Props) => {
  const handleChangePage = (change: number) => {
    const curr = props.current_page ?? 1;
    if (props.onChangePage) {
      props.onChangePage(curr + change);
    }
  };

  const extractShiftNames = (shifts: any): string => {
    const shiftNumbers = shifts.map((shift: any) => {
      const match = shift.name.match(/^(Shift (\d+))/);
      return match ? match[2] : '';
    }).filter((num : any) => num !== '');
  
    if (shiftNumbers.length === 0) {
      return '';
    }
  
    // Add "Shift" in front of the first shift number and join the rest with commas
    return `Shift ${shiftNumbers[0]}${shiftNumbers.length > 1 ? ', ' + shiftNumbers.slice(1).join(', ') : ''}`;
  };
  
  

  return (
    <div className="w-full py-2 border-primary">
      <motion.div
        className="min-h-[20rem]"
        key={props?.current_page}
        initial={{ opacity: 0.2, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          ease: "linear",
          duration: 0.4,
        }}
      >
        <Table>
          {props?.loading === false && props?.data.length === 0 && (
            <TableCaption className="text-lg py-4">
              <div>No Borrowing Data yet...</div>
              <Link to={"/borrowing/student"} className="text-blue-400 underline">Borrow Here</Link>
            </TableCaption>
          )}
          <TableHeader>
            <TableRow>
              <TableHead>Binusian ID</TableHead>
              {/* <TableHead>Name</TableHead> */}
              <TableHead>Room</TableHead>
              <TableHead>Request Date</TableHead>
              <TableHead>Borrowing Date</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Action</TableHead>
            </TableRow>
          </TableHeader>
          {props?.loading === true ? (
            <SkeletonTable rows={10} columns={6} />
          ) : (
            <TableBody>
              {props?.data?.map((d) => (
                d && (
                  <TableRow key={d.id}>
                    <TableCell>{d.student_nim_leader} - {d.name}</TableCell>
                    {/* <TableCell>{d.name}</TableCell> */}
                    <TableCell>{d.room?.room_code}</TableCell>
                    <TableCell>{dateFormat(d.created_at)}</TableCell>
                    <TableCell>{dateFormat(d.date)} ({extractShiftNames(d?.detail_shifts)})</TableCell>
                    <TableCell className={cn(getStatusClass(d.borrowing_status === "Finalized" ? d.attendance_status : d.borrowing_status), "font-semibold")}>
                    {d.borrowing_status === "Finalized" ? `${d.borrowing_status} - ${d.attendance_status}` : d.borrowing_status}  
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`${props?.detailUrl}${d.id}`}
                      >
                        <Button variant={"outline"} className="hover:bg-blue-600/90 hover:text-white transition-colors ease-linear duration-200">
                          Detail
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              ))}
            </TableBody>
          )}
        </Table>
      </motion.div>
      <div className="p-4 flex justify-between items-center bg-secondary">
        <span>
          Page {props?.current_page ?? 1} of {props?.last_page}
        </span>
        <div className="flex gap-2">
          <Button
            variant="outline"
            size="sm"
            disabled={(props?.current_page ?? 1) === 1}
            onClick={() => handleChangePage(-1)}
          >
            Previous
          </Button>

          <Button
            variant="outline"
            size="sm"
            onClick={() => handleChangePage(+1)}
            disabled={(props?.current_page ?? 1) === props?.last_page}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BorrowingTable;
