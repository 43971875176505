import React, { useEffect, useState } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select/Select';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, Area, AreaChart } from 'recharts';
import { Button } from '@/components/ui/button/Button';
import { Calendar } from '@/components/ui/calendar/Calendar';
import { DateRange } from 'react-day-picker';
import { format, subDays } from 'date-fns'; 
import useRoomByPic from '@/lib/hooks/useRoomByPic';
import { useUserStore } from '@/store/user/userStore';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table/Table';
import { CalendarIcon } from 'lucide-react';
import { IRoomType } from '@/models/room/RoomType';
import { IRoom } from '@/models/room/Room';

interface StatisticsProps {
  selectedRange: DateRange;
  setSummaryData: (data: Room[]) => void;
}

interface SelectDateRangeProps {
  selectedRange: DateRange;
  setSelectedRange: React.Dispatch<React.SetStateAction<DateRange>>;
}

interface Room {
  accepted: number;
  canceled: number;
  finalized: number;
  pending: number;
  rejected: number;
  room_code: string;
  total: number;
}

// interface StatusCount {
//   status: string;
//   count: number;
// }


const formatDate = (date: Date | undefined): string => {
  return date ? format(date, 'yyyy-MM-dd') : '';
};

// const calculateStatusCounts = (rooms: Room[]): StatusCount[] => {
//   const totals = rooms.reduce((acc, room) => {
//     acc.accepted += room.accepted;
//     acc.canceled += room.canceled;
//     acc.finalized += room.finalized;
//     acc.pending += room.pending;
//     acc.rejected += room.rejected;
//     return acc;
//   }, {
//     accepted: 0,
//     canceled: 0,
//     finalized: 0,
//     pending: 0,
//     rejected: 0
//   });

//   return [
//     { status: 'Accepted', count: totals.accepted },
//     { status: 'Canceled', count: totals.canceled },
//     { status: 'Finalized', count: totals.finalized },
//     { status: 'Pending', count: totals.pending },
//     { status: 'Rejected', count: totals.rejected }
//   ];
// };

const Statistics: React.FC<StatisticsProps> = ({ selectedRange, setSummaryData }) => {
  const [activeTab, setActiveTab] = useState('Room');
  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName);
  };
  const [data, setData] = useState<Room[]>([]);
  // const [status, setStatus] = useState("All");
  // const statuses = ["Accepted", "Finalized", "Rejected", "Canceled", "Pending"];
  const userStore = useUserStore();

  const { rooms } = useRoomByPic(userStore.data?.UserName);
  // const [statusCounts, setStatusCounts] = useState<StatusCount[]>([]) 
  // const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [roomTypes, setRoomTypes] = useState<IRoomType[]>([])
  const [selectedRoomType, setSelectedRoomType] = React.useState(-1);
  const [roomsData, setRoomsData] = useState<IRoom[]>([])
  const [typeData, setTypeData] = useState<any[]>([])
  

  // const handleBarClick = (data: StatusCount) => {
  //   if(data.status === selectedStatus) {
  //     setSelectedStatus(null);
  //     return;
  //   }
  //   setSelectedStatus(data.status);
  // };

  useEffect(() => {
    if(data.length > 0 && Array.isArray(data) && activeTab === "Room"){
      // setStatusCounts(calculateStatusCounts(data))
      setSummaryData(data);
    } else if(typeData.length > 0 && Array.isArray(typeData) && activeTab === "Room Type"){
      setSummaryData(typeData)
    }else{
      // setStatusCounts([]);
      setSummaryData([]);
    }
  },[data, typeData, activeTab])

  useEffect(() => {
    let ignore = false;
    console.log(roomsData)
    if(rooms.length > 0 || roomsData.length > 0){
      let roomIds = rooms.map(room => room.id);

      if(roomsData.length > 0 && selectedRoomType !== -1) roomIds = roomsData.map(room => room.id)
      
      const fetchData = async (
        ignore: boolean
      ) => {
        try {
          const res = await axios.get( import.meta.env.VITE_API_URL + 'borrowing/staff/statistics', {
            params: {
              date_start: formatDate(selectedRange?.from),
              date_end: selectedRange.to ? formatDate(selectedRange?.to) : formatDate(selectedRange?.from),
              rooms: roomIds
            },
          });
          if (ignore === true) return;
          // console.log(res.data)  
          setData(res?.data?.data);
        } catch (error) {
          console.log(error);
        }
      };
  
      fetchData(ignore);
      return () => {
        ignore = true;
      };
    }
  }, [rooms, selectedRange, roomsData]);

  const transformRoomTypeData = (data : any) => {
    return data.map((item : any) => {
      let shortRoomType = item.room_type.replace(/^Alam Sutera - /, ''); // Remove the prefix
  
      if (shortRoomType === "Communication & International Relation Laboratory") {
        shortRoomType = "Comm & Inter Lab";
      }
  
      return {
        room_code: shortRoomType,
        finalized: item.finalized,
        accepted: item.accepted,
        rejected: item.rejected,
        pending: item.pending,
        canceled: item.canceled,
      };
    });
  };

  useEffect(() => {
    let ignore = false;

    const fetchStatistics = async () => {
      try {
        const res = await axios.get(
          import.meta.env.VITE_API_URL+'borrowing/staff/leader-statistics',
          {
            params: {
              date_start: formatDate(selectedRange?.from),
              date_end: selectedRange.to ? formatDate(selectedRange?.to) : formatDate(selectedRange?.from),
            },
            withCredentials: true,
          }
        );
        if (ignore) return

        setTypeData(transformRoomTypeData(res.data.data))
      } catch (error) {
        
      }
    };

    if (activeTab === "Room Type") {
      fetchStatistics();
    }

    return () => {
      ignore = true;
    };
  }, [activeTab, selectedRange]);

  useEffect(() => {
    let ignore : boolean = false;

    const fetchData = async () => {
      try {
        const res = await axios.get(
          import.meta.env.VITE_API_URL + "room-type/borrowing",
          {
            params: {
              room_location_id: 1,
            },
            withCredentials: true,
          }
        );
        if (ignore === true) return;
        setRoomTypes(res.data.data);
      } catch (error) {
        setRoomTypes([]);
      }
    };
    fetchData();
    return () => {
        ignore = true;
    }
  }, []);

  useEffect(() => {
    let ignore = false;

    const fetchData = async (
      softwares: any[],
      ignore: boolean
    ) => {

      try {
        const res = await axios.get(
          import.meta.env.VITE_API_URL + "room/borrowing",
          {
            params: {
              room_location_id: 1,
              room_type_id: selectedRoomType,
              softwares: softwares ?? []
            },
            withCredentials: true,
          }
        );
        if (ignore === true) return;
          setRoomsData(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    if(selectedRoomType !== -1)
      fetchData([], ignore);
    else setRoomsData([])

    return () => {
      ignore = true;
    };
  }, [selectedRoomType]);

  return (
    <div className="p-6">
      <div className="flex gap-3 flex-col">
        <h2 className="text-xl font-semibold">Statistics</h2>
        <div className="flex justify-between flex-col sm:flex-row gap-4">
          <div className="flex gap-4">
            <Button
              variant="ghost"
              className={`btn px-3 py-2 rounded text-sm font-medium ${activeTab === "Room" ? 'border-b-2 border-blue-500' : ''}`}
              onClick={() => handleTabChange('Room')}
            >
              Room
            </Button>

            {(userStore?.data?.UserName.includes("CI") || 
              userStore?.data?.UserName.includes("MA") || 
              userStore?.data?.UserName.includes("MK") ||
              userStore?.data?.UserName.includes("RA")) && (<Button
              variant="ghost"
              className={`btn px-3 py-2 rounded text-sm font-medium ${activeTab === "Room Type" ? 'border-b-2 border-blue-500' : ''}`}
              onClick={() => handleTabChange('Room Type')}
            >
              Room Type
            </Button>)}
            {/* <Button
              variant="ghost"
              className={`btn px-3 py-2 rounded text-sm font-medium ${activeTab === "Asset" ? 'border-b-2 border-blue-500' : ''}`}
              onClick={() => handleTabChange('Asset')}
            >
              Asset
            </Button> */}
          </div>
          {/* <Select value={status} onValueChange={(v) => setStatus(v)}>
            <SelectTrigger className="w-[11rem]">
              <SelectValue  placeholder="Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="All">All Status</SelectItem>
              {statuses.map((s, i) => (
                <SelectItem
                  value={s}
                  key={i}
                  className={cn("mb-1", getStatusClass(s))}
                >
                  {s}
                </SelectItem>
              ))}
            </SelectContent>
          </Select> */}

          {activeTab === "Room" && (userStore?.data?.UserName.includes("CI") || 
                                    userStore?.data?.UserName.includes("MA") || 
                                    userStore?.data?.UserName.includes("MK") ||
                                    userStore?.data?.UserName.includes("RA")) && (
            <Select value={selectedRoomType + ""} onValueChange={(v) => setSelectedRoomType(+v)}>
              <SelectTrigger className="w-full md:w-[20vw]">
                <SelectValue placeholder="Room Type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={"-1"}>Select Room Type</SelectItem>
                {roomTypes.map((rt) => (
                  <SelectItem key={rt.id} value={rt.id + ""}>{rt.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}


        </div>
      </div>
      
      {(activeTab === "Room" && Array.isArray(data) && data.length === 0) || 
      (activeTab === "Room Type" && Array.isArray(typeData) && typeData.length === 0)  ? <div className='h-[18rem] text-center flex items-center justify-center'> No borrowing data on the date</div>  : (
      <>
      <ResponsiveContainer width="100%" height={300} className="mt-4">
      <AreaChart
        data={activeTab === "Room" ? data : typeData}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorFinalized" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#4caf50" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#4caf50" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorAccepted" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#2196F3" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#2196F3" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorRejected" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#f44336" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#f44336" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPending" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#ffeb3b" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#ffeb3b" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorCanceled" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#ff5722" stopOpacity={0.8} />  
            <stop offset="95%" stopColor="#ff5722" stopOpacity={0} /> 
          </linearGradient>
        </defs>
        <XAxis dataKey="room_code" tickMargin={10} axisLine={{ stroke: '#ccc', strokeWidth: 2 }} tick={{ fontSize: 14, fill: '#666' }}/>
        <YAxis axisLine={{ stroke: '#ccc', strokeWidth: 2 }} tick={{ fontSize: 14, fill: '#666' }}/>
        <Tooltip />
        <Legend />
        <Area
          type="monotone"
          dataKey="finalized"
          stroke="#4caf50"
          fillOpacity={1}
          fill="url(#colorFinalized)"
        />
        <Area
          type="monotone"
          dataKey="accepted"
          stroke="#2196F3"
          fillOpacity={1}
          fill="url(#colorAccepted)"
        />
        <Area
          type="monotone"
          dataKey="rejected"
          stroke="#f44336"
          fillOpacity={1}
          fill="url(#colorRejected)"
        />
        <Area
          type="monotone"
          dataKey="pending"
          stroke="#ffeb3b"
          fillOpacity={1}
          fill="url(#colorPending)"
        />
        <Area
          type="monotone"
          dataKey="canceled"
          stroke="#ffc107"
          fillOpacity={1}
          fill="url(#colorCanceled)"
        />
      </AreaChart>
      {/* <BarChart data={statusCounts}>
          <XAxis dataKey="status" axisLine={{ stroke: '#ccc', strokeWidth: 2 }} tick={{ fontSize: 14, fill: '#666' }} />
          <YAxis axisLine={{ stroke: '#ccc', strokeWidth: 2 }} tick={{ fontSize: 14, fill: '#666' }} />
          <Tooltip />
          <Bar dataKey="count" fill="#2196F3" onClick={handleBarClick}>
            {statusCounts?.map((index) => (
              <Cell key={`cell-${index}`} cursor="pointer" />
            ))}
          </Bar>
        </BarChart> */}
      </ResponsiveContainer>
      </>
    )}
    </div>
  );
};

const SelectDateRange: React.FC<SelectDateRangeProps> = ({ selectedRange, setSelectedRange }) => {
  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mb-4">Select Dates</h2>

      <div className="my-4 rounded bg-secondary p-2 flex justify-between items-center">
          {selectedRange.from ? (
              selectedRange.to ? (
                <>
                  {format(selectedRange.from, "LLL dd, y")} - {format(selectedRange.to, "LLL dd, y")}
                </>
              ) : (
                format(selectedRange.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          <CalendarIcon className="mr-2 h-4 w-4" />
      </div>

      <Calendar
        mode="range"
        captionLayout="dropdown-buttons"
        selected={selectedRange}
        onSelect={(range) => setSelectedRange(range ?? { from: undefined, to: undefined })}
        showOutsideDays
        fromYear={new Date().getFullYear() - 2}
        toYear={new Date().getFullYear()}
      />

    </div>
  );
};

interface SummaryProps {
  data: Room[];
}

const Summary: React.FC<SummaryProps> = ({ data }) => {
  return (
    <div className="mt-6 p-4">
      <h3 className="text-lg font-semibold mb-4">Requests Summary</h3>
      {data.length > 0 ? (<div className="rounded-md p-4 shadow-lg bg-secondary">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Room Code</TableHead>
              <TableHead>Finalized</TableHead>
              <TableHead>Accepted</TableHead>
              <TableHead>Rejected</TableHead>
              <TableHead>Pending</TableHead>
              <TableHead>Canceled</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((room, index) => (
              <TableRow key={index}>
                <TableCell>{room.room_code}</TableCell>
                <TableCell>{room.finalized}</TableCell>
                <TableCell>{room.accepted}</TableCell>
                <TableCell>{room.rejected}</TableCell>
                <TableCell>{room.pending}</TableCell>
                <TableCell>{room.canceled}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>) : <div>No data on the selected date</div>}
    </div>
  );
};

const Home = () => {
  const [selectedRange, setSelectedRange] = useState<DateRange>({ from: subDays(new Date(), 30), to: new Date() });
  const [summaryData, setSummaryData] = useState<Room[]>([]);

  return (
    <div className='flex flex-col rounded-lg mx-2 my-4 py-8 px-12 gap-4 w-full bg-secondary'>
      <div className='flex flex-col gap-4 lg:flex-row'>
        <div className='w-full rounded border shadow-lg bg-background'>
          <Statistics selectedRange={selectedRange} setSummaryData={setSummaryData}/>
        </div>
        <div className='min-w-fit rounded border shadow-lg bg-background'>
          <SelectDateRange selectedRange={selectedRange} setSelectedRange={setSelectedRange}/>
        </div>

      </div>
      <div className="w-full rounded border shadow-lg bg-background">
        <Summary data={summaryData} />
      </div>
    </div>
  );
};

export default Home;
