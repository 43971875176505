import ImageCard from "../components/card/image/ImageCard";
import room from "@assets/images/room.jpg";
import asset from "@assets/images/asset2.jpg";
import { motion } from "framer-motion";

const Borrowing = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="h-full flex-1 flex justify-center items-center"
    >
      <div className="h-full flex flex-col justify-start items-start flex-1 w-full lg:justify-center pt-24 bottom-0">
        <h2 className="scroll-m-20 px-8 text-xl sm:text-2xl md:text-3xl font-lsemibold tracking-tight first:mt-0 font-primary sm:text-center w-full lg:mb-8 lg:text-4xl text-center">Choose What You Want to Borrow</h2>
        <div className="flex flex-col lg:flex-row justify-center items-center  w-full px-8 pt-4 pb-10 md:justify-start lg:px-16 xl:px-32 lg:justify-center lg:gap-10 xl:gap-32 gap-4">
          <ImageCard title="Room Borrowing" imageUrl={room} destUrl="/borrowing/room/campus" />
          <ImageCard title="Asset Borrowing" imageUrl={asset} destUrl="/borrowing/asset/campus" />
        </div>
      </div>
    </motion.div>
  );
};

export default Borrowing;
