import React from "react";
import logo from "@assets/images/logo_lcas-removebg-preview.png";
import ribbon from "@assets/images/ribbon.png";

const LogoLCAS: React.FC = () => {
  return (
    <div className="flex h-12 w-12 rounded-[50%] overflow-hidden shadow-md border border-gray-200 bg-white">
      <img
        src={ribbon}
        alt="Ribbon"
        className="w-2"
      />
      <img
        src={logo}
        alt="LCAS Logo"
        className="w-full"
      />
    </div>
  );
};

export default LogoLCAS;
