import { extractShiftNames } from "@/components/borrowing/detail/BorrowingDetail";

type BorrowingProps = {
  borrowingCurrent?: any;
  assetBorrowingCurrent?: any;
};

export const AssetTransactions = ({borrowingCurrent, assetBorrowingCurrent }: BorrowingProps) => {
  return (
    <>
        <div className="mt-2">
        <strong>Existing Room Transactions</strong>
        {borrowingCurrent?.data && borrowingCurrent?.data?.length > 0 ? (
            borrowingCurrent.data.map((borrowing: any, index: number) => (
            <p key={index}>
                <strong>Borrowing Date:</strong> {borrowing.date} ({extractShiftNames(borrowing.detail_shifts || [])})
            </p>
            ))
        ) : (
            <p>No existing room borrowings found.</p>
        )}
        </div>

        <div className="mt-2">
        <strong>Existing Asset Transactions</strong>
        {assetBorrowingCurrent?.data?.length > 0 ? (
        <ul>
            {assetBorrowingCurrent.data.map((borrowing: any) => (
                <li key={borrowing.id}>
                <div>
                    <strong>Check-in Date:</strong> {borrowing.check_in_date} ({borrowing.check_in_shift?.shift_name})
                </div>
                <div>
                    <strong>Check-out Date:</strong> {borrowing.check_out_date} ({borrowing.check_out_shift?.shift_name})
                </div>
                </li>
            ))}
            </ul>
        ) : (
            <p>No existing asset borrowings found.</p>
        )}
        </div>

    </>
  );
};
