import { cn } from "@/lib/utils";
import { Skeleton } from "./Skeleton";

interface Props {
  count: number;
  containerClassName?: string;
  skeletonClassName?: string;
}

const SkeletonList = (props: Props) => {
  return (
    <div className={cn("w-full flex flex-col gap-2", props.containerClassName)}>
      {[...Array(props.count).keys()].map((a) => (
        <Skeleton
          key={a}
          className={cn(
            "w-full rounded-md bg-slate-200 h-12",
            props.skeletonClassName
          )}
        />
      ))}
    </div>
  );
};

export default SkeletonList;
