import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import { Input } from "@/components/ui/input/Input";
import { SyntheticEvent, useState } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => void;
  actionType: "ban" | "unban"; // Added prop for action type
};

export function BanDialog({ isOpen, onClose, onConfirm, actionType }: Props) {
  const [reason, setReason] = useState("");

  const handleConfirm = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onConfirm(reason);
    setReason('');
  };

  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{actionType === "ban" ? "Ban User" : "Unban User"}</DialogTitle>
        </DialogHeader>
        <div className="text-lg font-semibold mb-4">
          {actionType === "ban"
            ? "Please enter a reason for banning this user."
            : "Are you sure you want to unban this user?"}
        </div>
        
          {actionType === "ban" && (<Input
            placeholder="Ban reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="mb-4"
          />)}

        <DialogFooter className="flex gap-4">
          <Button
            className="w-auto"
            type="button"
            variant="blue"
            onClick={handleConfirm}
            disabled={actionType === "ban" && !reason.trim()}
          >
            {actionType === "ban" ? "Confirm Ban" : "Confirm Unban"}
          </Button>
          <DialogClose asChild>
            <Button type="button" variant="destructive">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
