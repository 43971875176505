
import { useRoomBorrowingStore } from "@/store/room_borrowing/roomBorrowingStore";
import { useState, useEffect } from "react";
import { useRoomBorrowingNext } from "../RoomBorrowing";
import MultiSelectFilter, { Option } from "@/components/multi-select-filter/MultiSelectFilter";
import { IShift } from "@/models/shift/Shift";
import axios from "axios";
import DateSelect, { convertToOptions } from "../../components/date/DateSelect";
import { useToast } from "@/lib/hooks/useToast";
import { formatDateString } from "@/lib/utils";

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const ChooseDates = () => {
  const store = useRoomBorrowingStore();
  const { setRoomBorrowingNext } = useRoomBorrowingNext();
  const [shifts, setShifts] = useState<IShift[]>([])
  const [selectedShift, setSelectedShift] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  const handleShiftChange = (selected: Option[] | Option | null) => {
    let arr: IShift[] = [];
    if (Array.isArray(selected)) {
      arr = selected.map((element) => {
        const shift = shifts.find((f) => f.id == element.id);
        return shift ? shift : null;
      }).filter((shift) => shift !== null) as IShift[];
      setSelectedShift(selected);
    }
    store.update("shift", arr);
  };

  const handleDate = (value: Date | undefined) => {
    if (value !== store.data.date) {
      store.update("date", value);
      setSelectedShift([]);
      store.update("shift", []);
    }
  };

  useEffect(() => {
    const selected = store.data.shift;
    const transformedShifts: Option[] = selected.map((s) => ({
      id: s.id.toString(), 
      name: s.name,
    }));
    setSelectedShift(transformedShifts);
    setRoomBorrowingNext(selected !== undefined && selected.length > 0);
  }, [store.data.shift]);

  useEffect(() => {
    if (!store.data.date) return;
    if (store.data.date) {
      const currentDate = new Date();
      const today = new Date(currentDate.setHours(0, 0, 0, 0));
      
      const storedDate = new Date(store.data.date);

      const storedDateMidnight = new Date(storedDate.setHours(0, 0, 0, 0));

      if (storedDateMidnight < today) {
        store.data.date = undefined;
        return;
      }
    }
    const data = store.data;
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${import.meta.env.VITE_API_URL}shift/available`,
          {
            params: {
              room_id: data.room,
              room_code: data.room_code,
              date: formatDateString(new Date(data?.date ?? "").toISOString()),
              room_type_id : data.room_type,
              room_location_id : data.location
            },
            withCredentials: true,
          }
        );
        const arr: IShift[] = [...res.data.data];
        setShifts(arr);
      } catch (error) {
        setShifts([]);
        toast({
          title: "Error",
          description: "Failed to fetch available shifts. Please try again later."
        });
        console.error("Error fetching shifts:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [store.data.date]);

  return (
    <DateSelect
      title="Select Date"
      date={new Date(store?.data?.date ?? "")}
      loading={loading}
      shiftData={shifts}
      onChangeShifts={handleShiftChange}
      onSelectDate={handleDate}
      selectedShifts={selectedShift}
      FilterComponent={
        <MultiSelectFilter
          options={convertToOptions(shifts)}
          handleSelected={handleShiftChange}
          selected={selectedShift}
        />
      }
    />
  );
};

export default ChooseDates;
