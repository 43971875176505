import { BorrowingAssetDetail, BorrowingDetailActions } from "@/components/borrowing/detail/BorrowingAssetDetail";
import { Button } from "@/components/ui/button/Button";
import LoadingIndicator from "@/components/ui/loading/LoadingIndicator";
import IAsset from "@/models/asset/Asset";
import { useAssetBorrowingStore } from "@/store/asset_borrowing/assetBorrowingStore"; // Assuming you have this store
import { useUserStore } from "@/store/user/userStore";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SubmitAssetBorrowing = () => {
  const assetBorrowingStore = useAssetBorrowingStore();
  const userStore = useUserStore();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const formatDateString = (dateString: any) => {
    const date = new Date(dateString); 
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`; // Format as month/day/year
  };


  const submitAssetBorrowing = () => {
    const borrowingData = assetBorrowingStore.data;
    setLoading(true);

    // console.log({
    //   check_in_date: borrowingData.pick_up_date,
    //   check_out_date: borrowingData.return_date,
    //   borrow_shift: borrowingData.pick_up_shift?.id,
    //   return_shift: borrowingData.return_shift?.id,
    //   purpose: borrowingData.purpose,
    //   assets: convertAssetsToIds(borrowingData.assets),
    //   student_nim_leader: userStore.data?.BinusianId,
    //   borrower_email: userStore.data?.Email,
    //   borrower_name: userStore.data?.Name,
    //   borrower_phone_number: borrowingData.phone,
    // })

    let date = borrowingData.pick_up_date;
    let date2 = borrowingData.return_date;

    let bDate = formatDateString(date)
    let rDate = formatDateString(date2)

    axios
      .post(import.meta.env.VITE_API_URL + "asset-borrowing/student/submit", {
        check_in_date: rDate,
        check_out_date: bDate,
        borrow_shift: borrowingData.pick_up_shift?.id,
        return_shift: borrowingData.return_shift?.id,
        purpose: borrowingData.purpose,
        assets: convertAssetsToIds(borrowingData.assets),
        student_nim_leader: userStore.data?.BinusianId,
        borrower_email: userStore.data?.Email,
        borrower_name: userStore.data?.Name,
        borrower_phone_number: borrowingData.phone,
      })
      .then(() => {
        setLoading(false);
        assetBorrowingStore.reset();
        localStorage.removeItem('asset_borrowing');
        navigate("/");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const convertAssetsToIds = (assets: IAsset[]): number[] => {
    return assets.map((asset) => asset.id);
  };

  return (
    <BorrowingAssetDetail
      data={{
        room: {
          has_assets: true,
          id: assetBorrowingStore.data.room,
          room_availability: true,
          room_code: assetBorrowingStore.data.room_code,
          room_name: "",
        },
        check_in_date: assetBorrowingStore.data.return_date ? assetBorrowingStore.data.return_date.toString() : "",
        check_out_date: assetBorrowingStore.data.pick_up_date ? assetBorrowingStore.data.pick_up_date.toString() : "",
        borrow_shift: assetBorrowingStore.data.pick_up_shift?.id,
        return_shift: assetBorrowingStore.data.return_shift?.id,
        purpose: assetBorrowingStore.data.purpose,
        assets: assetBorrowingStore.data.assets,
        borrowing_status: "Request",
        finalize_note: "",
        reason: "",
        student_nim_leader: userStore.data?.BinusianId,
        borrower_email: userStore.data?.Email,
        borrower_phone_number: assetBorrowingStore.data?.phone,
        created_at: "",
        id: 0,
      }}
    >
      <LoadingIndicator loading={loading}/>
      <BorrowingDetailActions>
        <Button
          disabled={loading}
          variant={"blue"}
          onClick={() => submitAssetBorrowing()}
        >
          Submit
        </Button>
      </BorrowingDetailActions>
    </BorrowingAssetDetail>
  );
};

export default SubmitAssetBorrowing;
