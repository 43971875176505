import { Button } from "@/components/ui/button/Button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog/Dialog"
import MultiSelectFilter, { Option } from "@/components/multi-select-filter/MultiSelectFilter";
import { DialogClose } from "@radix-ui/react-dialog";

type Props = {
    options: Option[],
    onSelectOption: (selected: Option[]) => void,
    selected: Option[],
    triggerText: string,
    title: string,
    onSubmit: () => void
}

export function FilterModal(props: Props) {
  return (
    <Dialog> 
      <DialogTrigger asChild>
        <Button variant="default" className="w-auto text-white">{props.triggerText}</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[720px]">
        <DialogHeader>
          <DialogTitle>{props.title}</DialogTitle>
        </DialogHeader>
        <div className="px-1 py-4">
            {/* CONTENT */}
            <MultiSelectFilter options={props.options} handleSelected={props.onSelectOption} selected={props.selected}/>
        </div>
        <DialogFooter>
          <DialogClose>
            <Button type="submit" onClick={props.onSubmit}>Save changes</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
