import React, { useEffect, useState } from 'react';
import { Calendar } from "@/components/ui/calendar/Calendar";
import { Option } from "@/components/multi-select-filter/MultiSelectFilter";
import MultiSelectFilter from "@/components/multi-select-filter/MultiSelectFilter";
import { Matcher } from "react-day-picker";
import { IShift } from "@/models/shift/Shift";
import axios from 'axios';
import LoadingIndicator from '@/components/ui/loading/LoadingIndicator';

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

type Props = {
  shiftData: IShift[];
  loading: boolean;
  selectedShifts: Option[] | Option | null;
  onChangeShifts: (options: Option[] | Option | null) => void;
  date: Date | undefined;
  onSelectDate: (date: Date | undefined) => void;
  title: string;
  FilterComponent: React.ReactElement;
  checkOutDate?: Date;
};


export const convertToOptions = (data: IShift[]) => {
  const opt: Option[] = [];
  data.forEach((a) => {
    opt.push({
      id: a.id + "",
      name: a.name,
    });
  });
  return opt;
};

const DateSelect = (props: Props) => {

  const [disabledDays, setDisabledDays] = useState<Matcher[]>([
    { before: tomorrow },   
    { dayOfWeek: [0] },   
  ]);

  useEffect(() => {
    let ignore = false
    const fetchHolidays = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}shift/holiday?year=${new Date().getFullYear()}`);
        if (response.data.status && !ignore) {
          const holidays = response.data.data;
          const holidayDates = holidays.map((holiday: { TheDate: string }) => new Date(holiday.TheDate));
          
          const existingDates = new Set(
            disabledDays.map((day: any) => day.day?.toISOString().split('T')[0])
          );

          // console.log(existingDates)

          const uniqueHolidays = holidayDates
            .filter((date: any) => !existingDates.has(date.toISOString().split('T')[0]))

            
          setDisabledDays(prevDays => [
            ...prevDays,
            ...uniqueHolidays
          ]);
        }
      } catch (error) {
        console.error('Error fetching holiday data:', error);
      }
    };

    fetchHolidays();

    return () => {
      ignore = true;
    };

  }, []);

  useEffect(() => {
    if (props.checkOutDate) {
      setDisabledDays((prevDays : any) => [
        ...prevDays,
        { before: props.checkOutDate },
      ]);
    }
  }, [props.checkOutDate]);
  

  
  return (
    <div className="w-full min-h-72 flex flex-col lg:flex-row gap-4">
      <div>
        <h3 className="pb-1">
          <div className="text-base md:text-lg font-semibold">{props.title}</div>
          <div className="text-gray-400 text-sm">
            You can only select tomorrow and the days after.
          </div>
        </h3>
        <Calendar
          mode="single"
          captionLayout="dropdown-buttons"
          selected={props.date}
          onSelect={(date) => props.onSelectDate(date)}
          disabled={disabledDays}
          fromYear={new Date().getFullYear()}
          toYear={new Date().getFullYear()+2}
        />
      </div>
      <div className="flex-1">
        <h3 className="pb-1">
          <div className="text-base md:text-lg font-semibold">Select Shift</div>
          <div className="text-gray-400 text-sm">
          {props?.FilterComponent?.type === MultiSelectFilter
              ? "You can select multiple shifts"
              : "You can select one shift"}
          </div>
        </h3>

        <h4
          className={`text-lg ${
            props.date === undefined
              ? "opacity-100"
              : "opacity-0 fixed top-[-1000px]"
          } transition-opacity ease-out duration-200`}
        >
          Please select a date before selecting shift.
        </h4>
        {props.loading ? (
          <LoadingIndicator loading={props.loading}/>
        ) : (props.shiftData.length < 1) ? ( 
        <div>
          No shifts available
        </div>
      ) : (
        <div
          className={`${
            props.date === undefined ? "opacity-0" : "opacity-100"
          } transition-opacity ease-in duration-200`}
        >
          {props.FilterComponent}
        </div>
      )}
      </div>
    </div>
  );
};

export default DateSelect;
