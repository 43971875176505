
import { useState, useEffect } from "react";
import { Option } from "@/components/multi-select-filter/MultiSelectFilter";
import { IShift } from "@/models/shift/Shift";
import axios from "axios";
import DateSelect, { convertToOptions } from "../../components/date/DateSelect";
import { useAssetBorrowingStore } from "@/store/asset_borrowing/assetBorrowingStore";
import { useAssetBorrowingNext } from "../AssetBorrowing";
import SingleSelectFilter from "@/components/multi-select-filter/SingleSelectFilter";

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const ChooseAssetDates = () => {
  const store = useAssetBorrowingStore();
  const { setAssetBorrowingNext } = useAssetBorrowingNext();
  const [pickUpShifts, setPickUpShifts] = useState<IShift[]>([])
  const [returnShifts, setReturnShifts] = useState<IShift[]>([])
  const [selectedPickUpShift, setSelectedPickUpShift] = useState< Option | null>(null);
  const [selectedReturnShift, setSelectedReturnShift] = useState< Option | null>(null);
  const [loading, setLoading] = useState(false);
  const [rLoading, setRLoading] = useState(false);
  const [show, setShow] = useState(false);

  const formatDateString = (dateString: any) => {
    const date = new Date(dateString); 
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`; // Format as month/day/year
  };

  const handlePickUpShiftChange = (selected: Option[] | Option | null) => {
    if(!Array.isArray(selected) && selected !== null) {
      const s = pickUpShifts?.find((f) => f.id == selected.id)
      setSelectedPickUpShift(selected);
      store.update("pick_up_shift", s);
    }
  };

  const handlePickUpDate = (value: Date | undefined) => {
    if (value !== store.data.pick_up_date) {
      store.update("pick_up_date", value);
      setSelectedPickUpShift(null);
      store.update("pick_up_shift", undefined);
    }
  };

  const handleReturnShiftChange = (selected: Option[] | Option | null) => {
    if(!Array.isArray(selected) && selected !== null) {
    const s = returnShifts?.find((f) => f.id == selected.id)
    setSelectedReturnShift(selected);
    store.update("return_shift", s);
    }
  };

  const handleReturnDate = (value: Date | undefined) => {
    if (value !== store.data.pick_up_date) {
      store.update("return_date", value);
      setSelectedReturnShift(null);
      store.update("return_shift", undefined);
    }
  };

  useEffect(() => {
    const selected = store.data.pick_up_shift;
    if (selected) {
      const transformedShift: Option = {
        id: selected.id.toString(), 
        name: selected.name,
      };
      setSelectedPickUpShift(transformedShift);
      setShow(true)
    } else {
      setSelectedPickUpShift(null);
    }
  }, [store.data.pick_up_shift]);

  useEffect(() => {
    if (!store.data.pick_up_date) return;
    if (store.data.pick_up_date) {
      const currentDate = new Date();
      const today = new Date(currentDate.setHours(0, 0, 0, 0));
      
      const storedDate = new Date(store.data.pick_up_date);

      const storedDateMidnight = new Date(storedDate.setHours(0, 0, 0, 0));

      if (storedDateMidnight < today) {
        store.data.pick_up_date = undefined;
        return;
      }
    }
    
    const data = store.data;
    const fetchData = async () => {
      setLoading(true);
      const res = await axios.get(
        `${import.meta.env.VITE_API_URL}shift/available`,
        {
          params: {
            room_id: data.room,
            room_code: data.room_code,
            room_type_id: data.room_type,
            date: formatDateString(data.pick_up_date),
          },
          withCredentials: true,
        }
      );
      const arr: IShift[] = [...res.data.data];
      setPickUpShifts(arr);
      setLoading(false);
    };
    fetchData();
  }, [store.data.pick_up_date]);

  useEffect(() => {
    const selected = store.data.return_shift;
    if (selected) {
      const transformedShift: Option = {
        id: selected.id.toString(), 
        name: selected.name,
      };
      setSelectedReturnShift(transformedShift);
      setAssetBorrowingNext(selected !== undefined);
    } else {
      setSelectedReturnShift(null);
    }
  }, [store.data.return_shift]);

  useEffect(() => {
    if (!store.data.return_date) return;
    if (store.data.return_date) {
      const currentDate = new Date();
      const today = new Date(currentDate.setHours(0, 0, 0, 0));
      
      const storedDate = new Date(store.data.return_date);

      const storedDateMidnight = new Date(storedDate.setHours(0, 0, 0, 0));

      if (storedDateMidnight < today) {
        store.data.return_date = undefined;
        return;
      }
    }
    const data = store.data;
    const fetchData = async () => {
      setRLoading(true);
      const res = await axios.get(
        `${import.meta.env.VITE_API_URL}shift/available`,
        {
          params: {
            room_id: data.room,
            room_code: data.room_code,
            room_type_id: data.room_type,
            date: formatDateString(data.return_date),
          },
          withCredentials: true,
        }
      );
      const arr: IShift[] = [...res.data.data];
      setReturnShifts(arr);
      setRLoading(false);
    };
    fetchData();
  }, [store.data.return_date]);

  return (
    <>
    <DateSelect
      title="Select PickUp Date"
      date={new Date(store?.data?.pick_up_date ?? "")}
      loading={loading}
      shiftData={pickUpShifts}
      onChangeShifts={handlePickUpShiftChange}
      onSelectDate={handlePickUpDate}
      selectedShifts={selectedPickUpShift}
      FilterComponent={<SingleSelectFilter options={convertToOptions(pickUpShifts)}
      handleSelected={handlePickUpShiftChange}
      selected={selectedPickUpShift}/>}
    />

    <div className="mt-3"/>
    { show && (<DateSelect
      title="Select Return Date"
      checkOutDate={new Date(store?.data?.pick_up_date ?? "")}
      date={store.data.return_date}
      loading={rLoading}
      shiftData={returnShifts}
      onChangeShifts={handleReturnShiftChange}
      onSelectDate={handleReturnDate}
      selectedShifts={selectedReturnShift}
      FilterComponent={<SingleSelectFilter options={convertToOptions(returnShifts)}
      handleSelected={handleReturnShiftChange}
      selected={selectedReturnShift}/>}
    />)}
    </>
  );
};

export default ChooseAssetDates;
