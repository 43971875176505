import IAsset from "@/models/asset/Asset";
import { IShift } from "@/models/shift/Shift";
import { create } from "zustand";


interface RoomBorrowingData {
  location: number;
  room_type: number;
  room: number;
  room_code: string
  date: Date | undefined,
  shift: IShift[],
  team: {
    binusian_id: string,
    email: string,
  }[],
  phone: string,
  need_internet?: boolean,
  need_chemicals?: boolean,
  purpose: string,
  current_page: number,
  borrower_count: number,
  assets: IAsset[],
}

interface RoomBorrowingState {
  data: RoomBorrowingData;
  update: (field:
    "location"
    | "room_type"
    | "room"
    | "room_code"
    | "date"
    | "shift"
    | "current_page"
    | "team"
    | "need_internet"
    | "need_chemicals"
    | "borrower_count"
    | "purpose"
    | "phone"
    | "assets",
    payload: any) => void;
  setBorrowing: (borrowingData: RoomBorrowingData) => void;
  reset: () => void;
}

const defaultData: RoomBorrowingData = {
  location: -1,
  room_type: -1,
  room: -1,
  room_code: "",
  date: undefined,
  shift: [],
  team: [],
  assets: [],
  phone: '',
  need_internet: false,
  need_chemicals: false,
  borrower_count: 0,
  purpose: "",
  current_page: 0,
};

export const useRoomBorrowingStore = create<RoomBorrowingState>((set) => ({
  data: defaultData,
  update: (field, payload) => {
    set((state) => {
      const newData = { ...state.data, [field]: payload };

      if (state.data.current_page === 0) {
        newData.room_type = -1;
        newData.room = -1;
        newData.room_code = "";
        newData.date = undefined;
        newData.shift = [];
        newData.team = [];
        newData.assets = [];
        newData.need_internet = false;
        newData.need_chemicals = false;
      } else if (state.data.current_page === 1) {
        newData.date = undefined;
        newData.shift = [];
        newData.assets = [];
        newData.need_internet = false;
        newData.need_chemicals = false;
      } else if (state.data.current_page === 2) {
        newData.assets = [];
        newData.need_internet = false;
        newData.need_chemicals = false;
      } else if (state.data.current_page === 3) {
        newData.need_internet = false;
        newData.need_chemicals = false;
      } 

      return { data: newData };
    });
  },
  setBorrowing: (borrowingData) => set(() => ({ data: { ...borrowingData } })),
  reset: () => set({
    data : defaultData
  })
}));
