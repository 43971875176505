import { Link } from "react-router-dom";
import styles from "../../Navigation.module.css";
const StudentNav = () => {
  return (
    <>
      <Link to={"/borrowing/student"} className={styles.navLink}>
        Borrowing
      </Link>
      <Link to={"/history/student"} className={styles.navLink}>
        History
      </Link>
      <Link to={"/feedback"} className={styles.navLink}>
        Feedback
      </Link>
    </>
  );
};

export default StudentNav;
