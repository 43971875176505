import LoadingIndicator from "@/components/ui/loading/LoadingIndicator";
import { dateFormat } from "@/lib/utils";
import IAssetBorrowingData from "@/models/borrowing/AssetBorrowingData";
import { ReactNode } from "react";

type ActionProps = {
    children: ReactNode;
};

const BorrowingDetailActions = (props: ActionProps) => {
    return (
        <div className="flex flex-row justify-end py-4">
            <div className="flex flex-row-reverse gap-4">{props.children}</div>
        </div>
    );
};

type Props = {
    data: IAssetBorrowingData | undefined;
    children: ReactNode;
};

const BorrowingAssetDetail = (props: Props) => {
    const data = props.data;
    console.log(data)

    const getStatusColor = (status: string) => {
        switch (status) {
            case "Accepted":
                return "text-green-600";
            case "Finalized":
                return "text-green-600";
            case "Rejected":
                return "text-red-600";
            case "Pending":
                return "text-muted-foreground";
            case "Canceled":
                return "text-red-600";
            default:
                return "";
        }
    };

    return (
        <>
            {data ? (
                <div className="flex-col px-12 pb-8 pt-12 w-full">
                    <div className="text-xl font-bold">Asset Borrowing Data</div>
                    <div className="text-sm font-semibold text-green-600 pb-4">
                        {data.id > 0 ? <>ID {data.id}</> : <>New</>}
                    </div>
                    <section className="flex flex-col pb-4 lg:flex-row w-full gap-4 items-start lg:h-[17rem] h-auto">
                        <div className="flex flex-col flex-1 gap-1 p-4 rounded-lg bg-secondary min-w-fit lg:w-auto h-full w-full">
                            {/* <div className="text-lg font-semibold">
                                Room : {data.room.room_code}
                            </div>
                            <div className="text-muted-foreground text-sm">
                                {data.room.room_name ?? ""}
                            </div>
                            <div className="text-muted-foreground text-sm">
                                {data.room.type?.name}
                            </div> */}
                            
                            <div className="flex flex-col lg:flex-row">
                                <div className="text-[1.1rem] font-semibold text-muted-foreground lg:w-40">Check-out Date</div>
                                <div className="text-primary font-semibold text-[1.1rem]">{dateFormat(data.check_out_date)} ({(data.borrow_shift)?.toString().startsWith("Shift") ? data.borrow_shift : "Shift "+ data.borrow_shift}) </div>
                            </div>
                            <div className="flex flex-col lg:flex-row">
                                <div className="text-[1.1rem] font-semibold  text-muted-foreground  lg:w-40">Check-in Date</div>
                                <div className="text-primary font-semibold text-[1.1rem] md:text-md">{dateFormat(data.check_in_date)} ({(data.return_shift)?.toString().startsWith("Shift") ? data.return_shift : "Shift "+ data.return_shift})</div>
                            </div>

                            <div className="flex flex-col lg:flex-row">
                                <div className="text-muted-foreground font-medium lg:w-40">Status</div>
                                <div className={`${getStatusColor(data.borrowing_status)} font-semibold`}>
                                    {data.borrowing_status}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-1 p-4 rounded-lg bg-secondary w-full lg:w-auto lg:h-full min-w-fit overflow-auto h-[17rem]">
                            <div className="text-lg font-semibold">Assets</div>
                            {data.assets?.map((a, index) => (
                                <div key={index} className="flex flex-row gap-4 py-4">
                                    <img
                                        src={
                                            a.images && a.images.length > 0
                                                ? a.images[0].path
                                                : "https://picsum.photos/200/300"
                                        }
                                        className="h-24 w-24"
                                        alt="image not found"
                                    />
                                    <div className="flex flex-col">
                                        <div className="text-sm font-semibold">{a.name}</div>
                                        <div className="text-sm">{a.description}</div>
                                        <div className="text-sm">{a.year}</div>
                                    </div>
                                </div>
                            ))}
                            {!data.assets || (data.assets.length < 1 && <div className="w-[14rem]">No Assets</div>)}
                        </div>
                    </section>
                    <section className="flex flex-col flex-1 gap-1 p-4 rounded-lg bg-secondary w-full md:w-auto h-60">
                        <div className="text-lg font-semibold">
                            Borrower : {data.student_nim_leader}
                        </div>
                        <div className="text-sm grid grid-cols-1 md:grid-cols-2 w-fit gap-y-1">
                            <div>Email</div>
                            <div className="text-muted-foreground">{data.borrower_email}</div>
                            <div>Phone</div>
                            <div className="text-muted-foreground">{data.borrower_phone_number}</div>
                            <div>Purpose</div>
                            <div className="text-muted-foreground">{data.purpose}</div>
                        </div>
                    </section>
                    {props.children}
                </div>
            ) : (
                <LoadingIndicator loading={true}/>
            )}
        </>
    );
};

export { BorrowingAssetDetail, BorrowingDetailActions };
