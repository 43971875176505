import IAsset from "@/models/asset/Asset";
import { IShift } from "@/models/shift/Shift";
import { create } from "zustand";


interface AssetBorrowingData {
  location: number;
  room_type: number;
  room: number;
  room_code: string
  pick_up_date: Date | undefined,
  pick_up_shift: IShift | undefined,
  return_date: Date | undefined,
  return_shift: IShift | undefined,
  phone: string,
  purpose: string,
  current_page: number,
  assets: IAsset[],
}

interface AssetBorrowingState {
  data: AssetBorrowingData;
  update: (field:
    "location"
    | "room_type"
    | "room"
    | "room_code"
    | "pick_up_date"
    | "pick_up_shift"
    | "return_date"
    | "return_shift"
    | "current_page"
    | "purpose"
    | "phone"
    | "assets",
    payload: any) => void;
  setBorrowing: (borrowingData: AssetBorrowingData) => void;
  reset: () => void; 
}

const defaultData: AssetBorrowingData = {
  location: -1,
  room_type: -1,
  room: -1,
  room_code: "",
  pick_up_date: undefined,
  pick_up_shift: undefined,
  return_date: undefined,
  return_shift: undefined,
  assets: [],
  phone: '',
  purpose: "",
  current_page: 0,
};

export const useAssetBorrowingStore = create<AssetBorrowingState>((set) => ({
  data: defaultData,
  update: (field, payload) => {
    set((state) => ({
      data: {
        ...state.data,
        [field]: payload,
      },
    }));
  },
  setBorrowing: (borrowingData) => set(() => ({ data: { ...borrowingData } })),
  reset: () => set({
    data : defaultData
  })
}));
