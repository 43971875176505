import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog/Dialog";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select/Select"; // Adjust the import based on your directory structure
import { useEffect, useState, SyntheticEvent } from "react";
import axios from "axios";
import RoomTransactions from "../RoomTransactions";
import { Input } from "@/components/ui/input/Input";

type Props = {
  onReject: (reason: string, reason_type: string) => void;
  title?: string;
  transactions?: any;
};

export function RejectDialog(props: Props) {
  const [reason, setReason] = useState(""); 
  const [reasonType, setReasonType] = useState("");
  const [reasonsList, setReasonsList] = useState<any[]>([]);

  useEffect(() => {
    const fetchReasons = async () => {
      try {
        const response = await axios.get(import.meta.env.VITE_API_URL + "reason/type?reason_type=Rejected");
        if (response.data.status) {
          const reasons = response.data.data.map((item: any) => ({
            id: item.id,
            description: item.reason_description,
          }));
          setReasonsList(reasons);
        }
      } catch (error) {
        console.error("Error fetching reasons:", error);
      }
    };

    fetchReasons();
  }, []);

  const handleReject = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onReject(reason, reasonType);
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="destructive">Reject</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Reject Borrowing</DialogTitle>
        </DialogHeader>

        {props.transactions && <RoomTransactions transactions={props.transactions} />}

        <div className="text-lg font-semibold">
          Are you sure you want to reject this borrowing?
        </div>

        <Select onValueChange={setReasonType} defaultValue="">
          <SelectTrigger>
            <SelectValue placeholder="Select a reason type" />
          </SelectTrigger>
          <SelectContent>
            {reasonsList.map((reasonItem) => (
              <SelectItem key={reasonItem.id} value={reasonItem.description}>
                {reasonItem.description}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Input
          placeholder="Additional notes"
          value={reason}
          onChange={(e) => setReason(e.target.value)}          
        />

        <DialogFooter className="justify-end flex flex-row gap-4">
          <Button type="button" variant={"destructive"} onClick={handleReject} disabled={!reason.trim() || !reasonType}>
            Reject
          </Button>
          <DialogClose asChild>
            <Button type="button" variant="blue">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
