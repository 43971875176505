import React, { useEffect, useState } from "react";
import confirmation from "@assets/icons/confirmation_icon.svg";
import warning from "@assets/icons/warning_icon.svg";
import error from "@assets/icons/error_icon.svg";
import { motion } from "framer-motion";

interface ToastInterface {
  message: string;
  type: "confirmation" | "warning" | "error";
}

const Toast: React.FC<ToastInterface> = ({ message, type }) => {
  const [icon, setIcon] = useState<string>("");
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (type === "confirmation") {
      setIcon(confirmation);
    } else if (type === "error") {
      setIcon(error);
    } else {
      setIcon(warning);
    }

    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [type]);

  if (!isVisible) return null;

  return (
    <motion.div className="fixed bottom-4 left-0 right-0 px-8 flex " initial={{ x: "-100%", opacity: 0 }} exit={{ x: "100%", opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.5, ease: "easeOut" }}>
      <div className={`h-full rounded-md flex justify-start items-center p-4 bg-secondary`}>
        {icon && <img src={icon} alt={`${type} icon`} className="mr-4 w-[2rem] h-[2rem]" />}

        <h4>{message}</h4>
      </div>
    </motion.div>
  );
};

export default Toast;
