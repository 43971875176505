import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useUserStore } from "@/store/user/userStore";
import LoadingIndicator from "@/components/ui/loading/LoadingIndicator";
import { useEffect, useState } from "react";

const StaffLayout = () => {
  const navigate = useNavigate();
  const userStore = useUserStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const success = await userStore.getData();
      setLoading(false); 

      if (!success) {
        navigate("/"); 
      }
    };

    if (userStore.data === undefined) {
      fetchUserData();
    } else {
      setLoading(false); 
    }
  }, [userStore]); 

  if (userStore.data === undefined && loading) {
    return <LoadingIndicator loading={loading} />;
  }

  if (userStore.data?.Role !== "Assistant" && !loading) {
    return <Navigate to="/home" replace />;
  }
  return <Outlet />;
};

export default StaffLayout;
