import { Button } from "@/components/ui/button/Button";
import { Input } from "@/components/ui/input/Input";
import { Label } from "@/components/ui/label/Label";
import { FormEvent, useState } from "react";
import styles from "./AssetBorrowerData.module.css";
import { cn } from "@/lib/utils";
import { useToast } from "@/lib/hooks/useToast";
import { useAssetBorrowingStore } from "@/store/asset_borrowing/assetBorrowingStore";
import { useAssetBorrowingNext } from "../AssetBorrowing";
import { useUserStore } from "@/store/user/userStore";

const AssetBorrowerData = () => {
  const store = useAssetBorrowingStore();
  const userStore = useUserStore();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const { setAssetBorrowingNext } = useAssetBorrowingNext();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAssetBorrowingNext(false);
    if (store.data.phone.trim() === "" || store.data.purpose.trim() === "") {
      toast({
        title: "Form error.",
        description: "Please fill all required fields...",
      });

      return;
    } else if (isNaN(+store.data.phone)) {
      toast({
        title: "Form error.",
        description: "Please fill phone number correctly...",
      });

      return;
    }
    setLoading(false);
    toast({
      title: "User data successfully saved.",
      description: "Please proceed to next step..",
    });
    setAssetBorrowingNext(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.inputContainer}>
        <Label>Purpose</Label>
        <Input
          placeholder="Borrowing Purpose"
          name="purpose"
          id="purpose"
          value={store.data.purpose}
          onChange={(e) =>
            store.update("purpose", e.target.value)
          }
        />
      </div>
      <div className={styles.inputContainer}>
        <Label>Phone</Label>
        <Input
          placeholder="Phone Number"
          name="phone"
          id="phone"
          value={store.data.phone}
          onChange={(e) =>
            store.update("phone", e.target.value)
          }
        />
      </div>
      <div className={cn(styles.inputContainer, "pb-6")}>
        <div className="flex w-full gap-4 py-2 items-end">
          <div className="w-full">
            <Label className="text-lg text-gray-600">Leader</Label>
            <Input
              disabled={true}
              value={`${userStore.data?.Email}`}
            />
          </div>
        </div>      
      </div>
      <div className="pt-4">
        <Button type="submit" variant={"blue"} disabled={loading}>
          Save Data
        </Button>
      </div>
    </form>
  );
};

export default AssetBorrowerData;
