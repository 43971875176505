import { useState } from "react";
import { Input } from "../ui/input/Input";
import { Button } from "../ui/button/Button";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { cn } from "@/lib/utils";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  containerClassName?: string,
  placeHolder?: string;
  onSearch: (search: string) => void;
  value?: string;
};

const Search = (props: Props) => {
  const [search, setSearch] = useState("")
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (props.onSearch)
        props.onSearch(search);
  }

  return (
    <form className={cn("w-full flex", props.containerClassName)} onSubmit={handleSubmit}>
      <Input
        className="w-full rounded-e-none focus-visible:ring-0 focus-visible:ring-offset-0"
        placeholder={props.placeHolder ?? "Search something"}
        defaultValue={props.defaultValue ?? ""}
        onChange={handleChange}
        value={props.value}
      />
      <div>
        <Button variant={"blue"} className="rounded-s-none" type="submit">
            <FaMagnifyingGlass/>
        </Button>
      </div>
    </form>
  );
};

export default Search;
