import BorrowingTable from "@/components/borrowing/table/BorrowingTable";
import { Button } from "@/components/ui/button/Button";
import useQueryParams from "@/lib/hooks/useQueryParams";
import { IBorrowingHistory } from "@/models/borrowing/BorrowingHistory";
import { useUserStore } from "@/store/user/userStore";
import axios from "axios";
import { useEffect, useState } from "react";
import FilterComponent, { Filter } from "../staff_history/components/filter/FilterComponent";
import useSemesters from "@/lib/hooks/useSemesters";
import AssetBorrowingTable from "@/components/borrowing/table/AsssetBorrowingTable";
import { IAssetBorrowingHistory } from "@/models/borrowing/AssetBorrowingHistory";

const StudentHistory = () => {
  const userStore = useUserStore();
  const [data, setData] = useState<IBorrowingHistory[]>([]);
  const [data2, setData2] = useState<IAssetBorrowingHistory[]>([]);
  const { params, updateParams } = useQueryParams();
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(false)
  const { semesters } = useSemesters();

  const changePage = (value: number) => {
    if (value >= 1 && value <= lastPage) {
      updateParams({ page: value + "" });
    }
  };

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      const page = params.get("page");
      const semester = params.get("semester_id") ?? -1; 
      const tab = params.get("tab") || activeTab; 
      const status = params.get("status") ?? "All";
      const apiUrl = activeTab == "Room" ? "borrowing/student/history" : "asset-borrowing/student/history"
      const dateFrom = params.get("dateFrom") ?? "";
      const dateTo = params.get("dateTo") ?? "";
      setLoading(true)
      try {
        const res = await axios.get(
          import.meta.env.VITE_API_URL + apiUrl,
          {
            params: {
              nim: userStore.data?.BinusianId,
              semester_id: semester,
              status: status,
              page: page ?? 1,
              date_start: dateFrom ,
              date_end: dateTo,
            },
          }
        );
        if (ignore === true) return;

        let obj;

        if(tab === "Room"){
          obj = res.data;
          setData(obj.data ?? []);
        }
        else {
          obj = res.data.data
          setData2(obj.data ?? []);
        }

        // console.log(obj);
 
        if (page === null || page != obj.current_page) {
          updateParams({
            page: obj.current_page,
          });
        }
        setLastPage(obj.last_page);
      } catch (error) {
        setData([])
        console.log(error);
      }
      setLoading(false);
    };

    fetchData();
    return () => {
      ignore = true;
    };
  }, [params]);


  const [activeTab, setActiveTab] = useState(params.get("tab") || "Room");
  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName);
    updateParams({ tab: tabName, page: "1" }); 
  };

  const handleFilter = (filter: Filter) => {
    updateParams({
      ...filter,
      page : '1'
    });
  };

  const handleClearFilters = () => {
    updateParams({
      status: "All",
      dateFrom: "",
      dateTo: "",
      page: "1",
      semester_id: "-1"
    });
  };

  useEffect(() => {
    handleClearFilters();
  }, [])

  return (
    <div className="container mx-auto py-10 px-8">  
      <div className="text-lg">My Borrowing History</div>
      <div className="flex gap-4 my-4">
        <Button
          variant="ghost"
          className={`btn shadow-lg w-full shadow-secondary px-3 py-2 rounded text-sm font-medium border-2 border-transparent ${activeTab === "Room" ?  'border-blue-500' : ''}`}
          onClick={() => handleTabChange('Room')}
        >
          Room
        </Button>
        <Button
          variant="ghost"
          className={`btn shadow-lg w-full shadow-secondary px-3 py-2 rounded text-sm font-medium border-2 border-transparent ${activeTab === "Asset" ?  'border-blue-500' : ''}`}
          onClick={() => handleTabChange('Asset')}
        >
          Asset
        </Button>
      </div>
      <FilterComponent
        handleClearFilter={handleClearFilters}
        isRoom={false}
        handleFilter={handleFilter}
        semeseters={semesters}
        rooms={[]}
        activeTab={activeTab}
      />
      {activeTab === 'Room' ? (
        <BorrowingTable
          data={data as IBorrowingHistory[]}
          onChangePage={changePage}
          current_page={+(params.get("page") ?? 1)}
          last_page={lastPage}
          loading={loading}
          detailUrl="/history/student/"
        />
      ) : (
        <AssetBorrowingTable
          data={data2 as IAssetBorrowingHistory[]}
          onChangePage={changePage}
          current_page={+(params.get("page") ?? 1)}
          last_page={lastPage}
          loading={loading}
          detailUrl="/history/asset/student/"
        />
        
      )}
    </div>
  );
};

export default StudentHistory;
