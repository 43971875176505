import { useSearchParams } from "react-router-dom";

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateParams = (obj: { [key: string]: any }) => {
    const params: { [key: string]: string } = {};
    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    for (let key in obj) {
      if (obj[key] === undefined || obj[key] === null) {
        delete params[key]
      } else {
        params[key] = obj[key];
      }
    }
    setSearchParams(params);
  }

  const clearParams = () => {
    setSearchParams(new URLSearchParams());
  }

  return {params: searchParams, updateParams, clearParams};
}

export default useQueryParams