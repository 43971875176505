import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export  function formatDateString(dateString: string) {
  const date = new Date(dateString); 

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`; 
};

export function dateFormat(val: string | Date) {
  const date = new Date(val);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct","Nov", "Dec"]
  return `${String(date.getDate()).padStart(2, "0")} ${months[date.getMonth()]} ${date.getFullYear()}`
}

export function dateQuery(val: string | Date) {
  const date = new Date(val);
  return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
}

export function getStatusClass(val: string) {
  if (val.startsWith("Finalized-")) {
    val = val.split("-")[1];
  }
  switch (val) {
    case "Accepted":
      return "text-blue-400";
    case "Present":
        return "text-green-600";
    case "Finalized":
      return "text-green-600";
    case "Rejected":
      return "text-red-600";
    case "Absent":
      return "text-red-600";
    case "Pending":
      return "text-yellow-600";
    case "Canceled":
      return "text-amber-600";
    default:
      break;
  }
}